import React, {useState} from 'react';
import Modal from '../auth/modal';
import Input from '../../inputs/input';
import Button from '../../inputs/button';
import { ReactSVG } from 'react-svg';
import '../../../styles/components/user/userupdate.styles.scss';
import { SENT_DATA } from '../../../utils/request';
import {notification} from 'antd';

export default function UpdateNumber({api, phone, id, onClose}) {
    const [number, setNumber] = useState('');
    const [recivedCode, setRecivedCode] = useState('');
    const [step, setStep] = useState(1);

    const openNotification = (placement) => {
        api.open({
            message: <div className="passwordMessage"><ReactSVG src="/images/user/succ.svg" /> <span>შენი ნომერი განახლებულია</span></div>,
            duration: 3,
            placement
        });
      };

    function changeNumber() {
        if(number) {
            SENT_DATA(`user/${id}`, {
                phone: number
            }).then((e)=>{ 
                onClose()
                if(e?.response?.status == 200){
                 openNotification('bottomRight')
                }else {
                    notification.open({
                        message: <div>შეცდომა</div>,
                        description:
                            <div >{e?.response?.data?.message}</div>,
                        duration: 3,
                    });
                }
            })
        }
    }

    return <>
        <Modal
            title="ტელეფონის ნომრის შეცვლა"
            onClose={onClose}
        >
            {
                step == 1 && 
                <div className="passwordUpdate">
                    <Input 
                        title="ახალი ნომერი"
                        value={number}
                        onChange={(e)=> setNumber(e)}
                        action="/images/user/phone.svg"
                        classNames="loginInputwith"
                        type="text"
                    />
                    <Button 
                        className="loginBtnAuth"
                        type="primary"
                        onClick={()=> {changeNumber(); onClose(); openNotification('bottomRight')}}
                    >
                        <span>გაგრძელება</span>
                        <ReactSVG 
                            className="loginIcon"
                            src="/images/user/next.svg" 
                        />
                    </Button>
                </div>
            }
            {/* {
                step == 2 && 
                <div className="passwordUpdate">
                    <Input 
                        title="მიღებული კოდი"
                        value={recivedCode}
                        onChange={(e)=> setRecivedCode(e)}
                        classNames="loginInput"
                        type="text"
                    />
                    <div className="resend">
                        <div>
                            <ReactSVG src="/images/user/resend.svg" />
                            <span>თავიდან მიღება</span>
                        </div>
                    </div>
                    <div className="updateNumBtns">
                        <Button 
                            className="backIcon"
                            type="primary"
                            onClick={()=>{setRecivedCode('');setStep(1)}}
                        >
                            <ReactSVG 
                                className="backicon"
                                src="/images/user/next.svg" 
                            />
                            <span>უკან</span>
                        </Button>
                        <Button 
                            className="done"
                            type="primary"
                            onClick={()=> {onClose(); openNotification('bottomRight')}}
                        >
                            <span>დადასტურება</span>
                            <ReactSVG 
                                className="loginIcon"
                                src="/images/user/next.svg" 
                            />
                        </Button>
                    </div>
                </div>
            } */}
        </Modal>
    </>
}