import React, {useState} from 'react';
import Modal from '../auth/modal';
import Input from '../../inputs/input';
import Button from '../../inputs/button';
import { ReactSVG } from 'react-svg';
import '../../../styles/components/user/userupdate.styles.scss';
import Check from '../auth/check';
import {Link} from 'react-router-dom';
import { SENT_DATA } from '../../../utils/request';
import {notification} from 'antd';

export default function UpdateMail({email, id, onClose}) {
    const [step, setStep] = useState(0);
    const [name, setName] = useState(email);
    const [newEmail, setNewEmail] = useState('');

    function changeMail() {
        if(newEmail) {
            SENT_DATA(`user/${id}`, {
                email: newEmail
            }).then((e)=>{ 
                onClose()
                if(!e?.response?.status == 200){
                    notification.open({
                        message: <div>შეცდომა</div>,
                        description:
                            <div >{e?.response?.data?.message}</div>,
                        duration: 3,
                    });
                }
            })
        }
    }

    return <>
        {step == 0 ? 
        <Modal
            title="ელფოსტის შეცვლა"
            onClose={()=> onClose()}
        >
            <Input 
                title="მიმდინარე ელფოსტა"
                value={name}
                onChange={(e)=> setName(e)}
                placeholder="ელფოსტა"
                action="/images/auth/mail.svg"
                classNames="loginInput"
                type="mail"
                actionClass="email"
                disabled
            />

            <Input 
                title="ახალი ელფოსტა"
                value={newEmail}
                onChange={(e)=> setNewEmail(e)}
                placeholder="ელფოსტა"
                action="/images/auth/mail.svg"
                classNames="loginInput"
                type="mail"
                actionClass="email"
            />

            <Button 
                className="loginBtnAuth"
                onClick={()=> {changeMail()}}
            >
                <span>გაგრძელება</span>
                <ReactSVG 
                    className="next"
                    src="/images/auth/nextStep.svg" 
                />
            </Button>
        </Modal> :
        <Check 
            backStep={()=> setStep(0)}
            onClose={()=> onClose()}
        >
            <div className="message">
                <div className="imgMessage">
                    <ReactSVG src="/images/auth/checkMail.svg" />
                </div>
                <div className="messagetext">
                    <h3>შეამოწმე ელფოსტა</h3>
                    <span>
                        პაროლის აღდგენის ინსტრუქცია ელფოსტაზე გამოგიგზავნეთ.
                    </span>
                </div>
                <Button 
                    className="close"
                    onClick={()=> onClose()}
                >
                    <Link to="/user/update">
                        <span>დახურვა</span>
                    </Link>
                </Button>
            </div>
        </Check>
    }
    </>
}