import { useQuery } from "@tanstack/react-query";
import { GET_DATA } from "../utils/request";

export function useLesson(lessonId) {
  const {
    data: lesson,
    isError: isLessonError,
    isLoading: isLessonLoading,
    isSuccess: isLessonSuccess,
    refetch: refetchLesson,
  } = useQuery(
    [`lesson-${lessonId}`, [lessonId]],
    async () => GET_DATA(`/lesson/${lessonId}`),
    {
      enabled: lessonId !== undefined,
    }
  );

  return {
    lesson,
    isLessonError,
    isLessonLoading,
    isLessonSuccess,
    refetchLesson,
  };
}
