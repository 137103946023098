import {useState, useEffect} from 'react';
import {ReactSVG} from 'react-svg';
import '../../styles/components/inputs/select.styles.scss';

export default function Select({
    options,
    onChange,
    label,
    disabled,
    value,
    className,
    labelStyle = "inside",
    inputClassname
  }) {
      const [isOpen, setIsOpen] = useState(false);
      const [selected, setSelected] = useState(
          options?.find((option) => option.value === value)
      );
  
      useEffect(() => {
          setSelected(options.find((option) => option.value === value));
      }, [value]);
  
    return (
      <div className={`selectContainer ${className}`}>
        {labelStyle === "outside" && (
          <div className={'selectLabel'}>
            <span>{label}</span>
          </div>
        )}
        <div
          className={`${disabled ? 'selectDisabled' : 'selectStyl'}`}
        >
          {disabled ? null : (
            <div
              className={`selectBody ${inputClassname}`}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <div
                    className={`selectLabelX ${value && 'selectedLabel'}`}
              >
                <span>
                  {selected?.label || (labelStyle === "inside" && label)}
                </span>
              </div>
              <ReactSVG
                src={"/images/input/arrow.svg"}
                className={`selectArrow ${isOpen && 'arrowUp'}`}
              />
            </div>
          )}
          <div
            className={`selectWrapper ${isOpen && 'activeWrapper'}`}
          >
            {options.map((option) => (
              <div
                key={option.value}
                className={`slectOption ${option === selected && 'selectActive'}`}
                onClick={() => {
                  onChange(option.value);
                  setSelected(option);
                  setIsOpen(!isOpen);
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }