import React, {useState} from 'react';
import Button from '../../../components/inputs/button';
import { ReactSVG } from 'react-svg';
import UpdatePassword from '../../../components/modals/user/password';
import PersonalUpdate from '../../../components/modals/user/personal';
import UpdateNumber from '../../../components/modals/user/number';
import ClassView from '../../../components/modals/teacher/Classview';
import UpdateMail from '../../../components/modals/user/mail';
import * as ANT from 'antd';
import { notification } from 'antd';
import 'antd/dist/antd.css';
import "../../../styles/pages/user.styles.scss";

let coursesData = [
    {
        id: 0,
        name:'ისწავლე HTML',
        length: 12,
        pass: 4,
    }
]

export default function TeacherProfile() {
    const [courseType, setCourseType] = useState('მიმდინარე');
    const [isEdit, setIsEdit] = useState(false);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [updateInfo, setUpdateInfo] = useState(false);
    const [updatePhone, setUpdatePhone] = useState(false);
    const [schools, setSchools] = useState([
        {
            id: 0,
            name: '53-ე სკოლა, VIIა კლასი',
            members: [
                {
                    id: 0,
                    name: 'საბა ჩიტაიშვილი',
                    mail: 'Saba.chitaishvili@geolab.edu.ge'
                },
                {
                    id: 1,
                    name: 'საბა ჩიტაიშვილი',
                    mail: 'Saba.chitaishvili@geolab.edu.ge'
                },
                {
                    id: 2,
                    name: 'საბა ჩიტაიშვილი',
                    mail: 'Saba.chitaishvili@geolab.edu.ge'
                },
            ]
        },
        {
            id: 1,
            name: '53-ე სკოლა, VIIა კლასი',
            members: [
                {
                    id: 0,
                    name: 'საბა ჩიტაიშვილი',
                    mail: 'Saba.chitaishvili@geolab.edu.ge'
                },
                {
                    id: 1,
                    name: 'საბა ჩიტაიშვილი',
                    mail: 'Saba.chitaishvili@geolab.edu.ge'
                },
                {
                    id: 2,
                    name: 'საბა ჩიტაიშვილი',
                    mail: 'Saba.chitaishvili@geolab.edu.ge'
                },
            ]
        },
        {
            id: 2,
            name: '53-ე სკოლა, VIIა კლასი',
            members: [
                {
                    id: 0,
                    name: 'საბა ჩიტაიშვილი',
                    mail: 'Saba.chitaishvili@geolab.edu.ge'
                },
                {
                    id: 1,
                    name: 'საბა ჩიტაიშვილი',
                    mail: 'Saba.chitaishvili@geolab.edu.ge'
                },
                {
                    id: 2,
                    name: 'საბა ჩიტაიშვილი',
                    mail: 'Saba.chitaishvili@geolab.edu.ge'
                },
                {
                    id: 2,
                    name: 'საბა ჩიტაიშვილი',
                    mail: 'Saba.chitaishvili@geolab.edu.ge'
                },
                {
                    id: 2,
                    name: 'საბა ჩიტაიშვილი',
                    mail: 'Saba.chitaishvili@geolab.edu.ge'
                },
                
            ]
        }
    ])
    const [isAdd, setIsAdd] = useState(false);
    const [schoolName, setSchoolName] = useState('');
    const [schoolView, setSchoolView] = useState({
        id: null,
        open: false
    })
    const [updateMailModal, setUpdateMail] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    function addSchool() {
        if(schoolName !== ''){
            setSchools((prev)=>([...prev, {
                id: schools.length + 1,
                name: schoolName,
                members: []
            }])); setSchoolName(''); setIsAdd(false)
        }else {
            setSchoolName('');
            setIsAdd(false)
        }
    }

    return <>
        {contextHolder}
        {
            updatePassword && <UpdatePassword api={api} onClose={()=> setUpdatePassword(false)} /> 
        }
        {
            updateInfo && <PersonalUpdate api={api} onClose={()=> setUpdateInfo(false)} /> 
        }
        {
            updatePhone && <UpdateNumber api={api} onClose={()=> setUpdatePhone(false)} /> 
        }
         {
            updateMailModal && <UpdateMail onClose={()=> setUpdateMail(false)} />
        }
        {
            schoolView.open && 
            <ClassView 
                schoolView={schoolView} 
                setSchoolView={setSchoolView} 
                schools={schools} 
                setSchools={setSchools} 
            />
        }
        <div className="user_profile">
            <div className="user_space">
                <div className="user_ul">
                    <div className="user_tool">
                        <div className="user_avatar">
                            <ReactSVG className="user_profile_avatar" src="/images/user/Avatars.svg" />
                            <ReactSVG className="user_edit_icon" src="/images/user/userEdit.svg" />
                        </div>
                        <div className="user_information">
                            <h2>ანანო ასპანიძე</h2>
                            <h4>მასწავლებელი</h4>
                        </div>
                    </div>
                    <div className="user_edit">
                        <Button 
                            className="user_edit_icon" 
                            onClick={()=> setIsEdit(!isEdit)} 
                        >
                            რედაქტირება
                            <ReactSVG src="/images/user/editicon.svg" />
                        </Button>
                        <Button 
                            className="user_logout_icon"  
                        >
                            გასვლა
                            <ReactSVG src="/images/user/logout.svg" />
                        </Button>

                        <div className={`user_edit_block ${isEdit && 'active_user_edit_block'}`}>
                            <li onClick={()=> {setIsEdit(false); setUpdatePassword(true)}}>პაროლის შეცვლა</li>
                            <li onClick={()=> {setIsEdit(false); setUpdateMail(true)}}>ელფოსტის შეცვლა</li>
                            <li onClick={()=> {setIsEdit(false); setUpdateInfo(true)}}>პირადი ინფორმაციის შეცვლა</li>
                            <li onClick={()=> {setIsEdit(false); setUpdatePhone(true)}}>ტელეფონის ნომრის შეცვლა</li>
                        </div>
                    </div>
                </div>
            </div>
            <div className="user_courses">
                <div className="user_table">
                    <div className="user_table_titles">
                        <button className={`${courseType == 'მიმდინარე' && 'tab_active'}`} onClick={()=> setCourseType('მიმდინარე')}>მიმდინარე</button>
                        <button className={`${courseType == 'დასრულებული' && 'tab_active'}`} onClick={()=> setCourseType('დასრულებული')}>დასრულებული</button>
                        <button className={`${courseType == 'სერტიფიკატები ' && 'tab_active'}`} onClick={()=> setCourseType('სერტიფიკატები')}>სერტიფიკატები</button>
                        <button className={`${courseType == 'ჩემი მოსწავლეები' && 'tab_active'}`} onClick={()=> setCourseType('ჩემი მოსწავლეები')}>ჩემი მოსწავლეები</button>
                        <button className={`${courseType == 'ჩემი კურსები' && 'tab_active'}`} onClick={()=> setCourseType('ჩემი კურსები')}>ჩემი კურსები</button>
                    </div>
                    <div className={`user_current_courses ${courseType == 'მიმდინარე' && 'user_activeTab'}`}>
                        {
                            coursesData?.map((course, key)=>(
                                <div className="user_current_course" key={key}>
                                    <div className="user_current_course_image">
                                        <img src="/images/user/courseimg.png" alt="" />
                                    </div>
                                    <div className="user_current_course_info">
                                        <h2>{course.name}</h2>
                                        <h3><ReactSVG src="/images/user/time.svg" /> {course.pass} დარჩენილი {course.length}-დან</h3>
                                        <Button
                                            className="user_current_course_btn"
                                        >
                                            გაგრძელება 
                                            <ReactSVG src="/images/user/cont.svg" />
                                        </Button>
                                    </div>
                                    <div style={{ width: `${course.pass * 100 / course.length}%` }} className="user_current_course_progress"></div>
                                </div>
                            ))
                        }
                    </div>
                    <div className={`user_done_courses ${courseType == 'დასრულებული' && 'user_activeTab'}`}>
                        {
                            coursesData?.map((course, key)=>(
                                <div className="user_current_course" key={key}>
                                    <div className="user_current_course_image">
                                        <img src="/images/user/js.png" alt="" />
                                    </div>
                                    <div className="user_current_course_info">
                                        <h2>{course.name}</h2>
                                        <h3><ReactSVG src="/images/user/done.svg" /> დასრულებული</h3>
                                        <Button
                                            className="user_current_course_btn"
                                        >
                                            ვრცლად 
                                            <ReactSVG src="/images/user/more.svg" />
                                        </Button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className={`user_courses_certificates ${courseType == 'სერთიფიკატები' && 'user_activeTab'}`}>
                        {
                            coursesData?.map((course, key)=>(
                                <div className="user_current_course" key={key}>
                                    <div className="user_current_course_image">
                                        <img src="/images/courses/certificate.png" alt="" />
                                    </div>
                                    <div className="user_current_course_info">
                                        <h2>{course.name}</h2>
                                        <h3>სერტიფიკატი</h3>
                                        <div className="user_certificat_btns">
                                            <button>
                                                გადმოწერა
                                                <ReactSVG src="/images/user/download.svg" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className={`schools_container ${courseType == 'ჩემი მოსწავლეები' && 'shcool_activetab'}`}>
                    {
                            schools?.map((school, key)=>(
                                <div className='school'>
                                    <div>
                                        <h2>{school.name}</h2>
                                        <h4>{school.members.length} მოსწავლე</h4>
                                    </div>
                                    <div className="user_member">
                                        {
                                            school.members?.length > 4 ? school.members?.map((member, i)=> {
                                                if(i < 3){
                                                    return <>
                                                    <div className="user_member_icon">
                                                        <ANT.Tooltip placement="bottom" title={member.name}>
                                                            <ReactSVG src="/images/user/icon.svg" />
                                                        </ANT.Tooltip>
                                                    </div>
                                                    </>
                                                }else if(i == 4) {
                                                    return <>
                                                        <div className="user_member_icon">
                                                            <ReactSVG className='user_member_all' src="/images/user/icon.svg" />
                                                            <span>+</span>
                                                        </div>
                                                    </>
                                                }
                                            }) : 
                                            school.members?.map((member)=> {
                                                    return <>
                                                        <div className="user_member_icon">
                                                            <ANT.Tooltip placement="bottom" title={member.name}>
                                                                <ReactSVG src="/images/user/icon.svg" />
                                                            </ANT.Tooltip>
                                                        </div>
                                                    </>
                                                })
                                        }
                                    </div>
                                    <div>
                                        <button onClick={()=> setSchoolView({id: school.id, open: true})} className="show_more_btn_members">
                                            ვრცლად
                                            <ReactSVG src="/images/user/showmore.svg" />
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            isAdd ? <div className="school_add">
                                <div>
                                    <input value={schoolName} onChange={(e)=> setSchoolName(e.target.value)} placeholder='შეიყვანე კლასის სახელი' type="text" />
                                    <h4>მოსწავლეები არ არის</h4>
                                </div>
                                <button onClick={()=> addSchool()}>
                                    შექმნა
                                    <ReactSVG src="/images/user/plus.svg" />
                                </button>
                            </div> : 
                            <button className='add_school_btn' onClick={()=> setIsAdd(true)}>
                                ახლის დამატება
                                <ReactSVG className="add_school_btn_svg" src="/images/user/plus.svg" />
                            </button>
                        }
                        
                    </div>
                    <div className={`user_done_courses ${courseType == 'ჩემი კურსები' && 'user_activeTab'}`}>
                        {
                            coursesData?.map((course, key)=>(
                                <div className="user_current_course" key={key}>
                                    <div className="user_current_course_image">
                                        <img src="/images/user/courseimg.png" alt="" />
                                    </div>
                                    <div className="user_current_course_info">
                                        <h2>{course.name}</h2>
                                        <h3><ReactSVG src="/images/user/time.svg" /> {course.pass} დარჩენილი {course.length}-დან</h3>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}