import { ReactSVG } from 'react-svg';
import Button from '../../components/inputs/button';

export default function UpdateMail() {
    return <>
        <div className="updateMailPage">
            <div className="errorMessage">
                <div className="errorLogo">
                    <ReactSVG src="/images/user/updatemail.svg" />
                </div>
                <h2>შენ წარმატებით განაახლე ელფოსტა</h2>
                <Button
                    className="backToMain"
                >
                    <span>ჩემი პროფილი</span>
                </Button>
            </div>
        </div>
    </>
}