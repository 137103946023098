import { useQuery } from "@tanstack/react-query";
import { GET_DATA } from "../utils/request";

export function useCourse(coursesId) {
  const {
    data: course,
    isError: isCourseError,
    isLoading: isCourseLoading,
    isSuccess: isCourseSuccess,
    refetch: refetchCourse,
  } = useQuery(
    [`course-${coursesId}`, [coursesId]],
    async () => GET_DATA(`/course/${coursesId}`),
    {
      enabled: coursesId !== undefined,
    }
  );

  return {
    course,
    isCourseError,
    isCourseLoading,
    isCourseSuccess,
    refetchCourse,
  };
}
