import { useQuery } from "@tanstack/react-query";
import { GET_DATA } from "../utils/request";

export function useExercise(topicId) {
  const {
    data: exercise,
    isError: isExerciseError,
    isLoading: isExerciseLoading,
    isSuccess: isExerciseSuccess,
    refetch: refetchExercise,
  } = useQuery(
    [`exercise-${topicId}`, [topicId]],
    async () => GET_DATA(`/exercise/?topic_id=${topicId}`),
    {
      enabled: topicId !== undefined,
    }
  );

  return {
    exercise,
    isExerciseError,
    isExerciseLoading,
    isExerciseSuccess,
    refetchExercise,
  };
}
