import { ReactSVG } from 'react-svg';
import Button from '../../components/inputs/button';
import '../../styles/pages/404.styles.scss';
import { Link } from 'react-router-dom';

export default function ErrorPage() {
    return <>
        <div className="errorPage">
            <div className="errorMessage">
                <div className="errorLogo">
                    <ReactSVG src="/images/error/errorImg.svg" />
                </div>
                <h2>გვერდი ვერ მოიძებნა</h2>
                <Link href="/">
                <Button
                    className="backToMain"
                    >
                    <span>მთავარზე დაბრუნება</span>
                </Button>
                    </Link>
            </div>
        </div>
    </>
}