import '../../styles/components/modals/about.styles.scss';
import Modal from './modal';

export default function Rules({
    onClose
}) {
    return <>
        <Modal 
            title="წესები და პირობები"
            onClose={onClose}
        >
            <div className="about">
                <p>
                    ლორემ იპსუმ ტელეარხებმა ხიბლავს გაფორმებასა ჰყვებოდა, 
                    უდმურტეთიდან მკვახე ჰზიდოს წამოგვცდენოდა გარემოება დამეთმო. 
                    ოდისეის ტიალი ცხელის, ეგზოტიკურ ზღაპრებს მსხვერპლმა ჩაუკრა 
                    ამბავსაც დაიმორჩილა, ქუდმოუხდელი ბეტ ჯენზე უმის. მკვახე 
                    ესპანურის შევუფარდო, ჯჯაყო ჩაექსოვნენ, გაჩვარებული ფუღუროში 
                    უმის ჩაკვლის გოლიათური.
                </p>
                <p>
                    ლორემ იპსუმ ტელეარხებმა ხიბლავს გაფორმებასა ჰყვებოდა, 
                    უდმურტეთიდან მკვახე ჰზიდოს წამოგვცდენოდა გარემოება დამეთმო. 
                    ოდისეის ტიალი ცხელის, ეგზოტიკურ ზღაპრებს მსხვერპლმა ჩაუკრა 
                    ამბავსაც დაიმორჩილა, ქუდმოუხდელი ბეტ ჯენზე უმის. მკვახე 
                    ესპანურის შევუფარდო, ჯჯაყო ჩაექსოვნენ, გაჩვარებული ფუღუროში 
                    უმის ჩაკვლის გოლიათური.
                </p>
            </div>
        </Modal>
    </>
}