import { useState, useEffect } from "react";
import Button from "../../../components/inputs/button";
import { ReactSVG } from "react-svg";
import "../../../styles/pages/coursesDetailed.styles.scss";
import {
  Link,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { useCourse } from "../../../hooks/useCourse";
import { useSelector } from "react-redux";
import useLessons from "../../../hooks/useLessons";

let coursePieces = [
  {
    id: 0,
    title: "HTML ელემენტები და სტრუქტურა",
    description:
      "ამ ეტაპზე გაეცნობი თუ რა ნაწილებისგან უნდა შედგებოდეს HTML დოკუმენტი.",
    details: [
      {
        lesson: "როგორ მუშაობს HTML?",
      },
      {
        quiz: "როგორ მუშაობს HTML?",
      },
      {
        lesson: "HTML დოკუმენტის ნაწილები",
      },
      {
        quiz: "HTML დოკუმენტის ნაწილები",
      },
      {
        project: "შეასრულე ეტაპის შემაჯამებელი დავალება",
      },
    ],
  },
  {
    id: 1,
    title: "შექმენი HTML თეიბლები",
    description:
      "ამ ეტაპზე ისწავლი სინტაქსს, რომელიც HTML თეიბლების შექმნისას გამოგადგება.",
    details: [
      {
        lesson: "როგორ მუშაობს HTML?",
      },
      {
        quiz: "როგორ მუშაობს HTML?",
      },
      {
        lesson: "HTML დოკუმენტის ნაწილები",
      },
      {
        quiz: "HTML დოკუმენტის ნაწილები",
      },
      {
        project: "შეასრულე ეტაპის შემაჯამებელი დავალება",
      },
    ],
  },
  {
    id: 2,
    title: "ფორმებთან მუშაობა",
    description:
      "ამ ეტაპზე გაიგებ HTML ფორმების შესახებ, ისწავლი მათ შექმნას და გამოყენებას.",
    details: [
      {
        lesson: "როგორ მუშაობს HTML?",
      },
      {
        quiz: "როგორ მუშაობს HTML?",
      },
      {
        lesson: "HTML დოკუმენტის ნაწილები",
      },
      {
        quiz: "HTML დოკუმენტის ნაწილები",
      },
      {
        project: "შეასრულე ეტაპის შემაჯამებელი დავალება",
      },
    ],
  },
  {
    id: 3,
    title: "სემანტიკური HTML",
    description:
      "აქ ისწავლი თუ როგორ წერო გამართული HTML დოკუმენტი სემანტიკური თეგების გამოყენებით",
    details: [
      {
        lesson: "როგორ მუშაობს HTML?",
      },
      {
        quiz: "როგორ მუშაობს HTML?",
      },
      {
        lesson: "HTML დოკუმენტის ნაწილები",
      },
      {
        quiz: "HTML დოკუმენტის ნაწილები",
      },
      {
        project: "შეასრულე ეტაპის შემაჯამებელი დავალება",
      },
    ],
  },
];

export default function CoursesDetailed() {
  const user = useSelector((state) => state.user);
  //course id
  const params = useParams();

  const { course } = useCourse(params.coursesId);
  const { lessons } = useLessons(params.coursesId);

  const [state, setState] = useState("კურსის შესახებ");
  const [pieces, setPieces] = useState({
    data: coursePieces.filter((_, i) => i < 3),
    show: false,
  });
  const [pieceItem, setPieceItem] = useState(null);

  return (
    <>
      <div className="coursesDetailed">
        <div
          style={{ backgroundImage: `url(${course?.image_path})` }}
          className="coursesHeader"
        >
          {/* <ReactSVG src={course?.image_path} /> */}
        </div>
        <div className="courseDetails-page">
          <div className="courseHeader">
            <div>
              <h2>{course?.name}</h2>
              <div>
                <span>
                  <ReactSVG src="/images/courses/length.svg" />
                  {course?.lesson_number} გაკვეთილი
                </span>
              </div>
            </div>
            {lessons?.length > 0 && Array.isArray(lessons) && params && (
              <Link
                to={`/task?lesson=${lessons[0]?.id}&id=${lessons[0]?.topics[0].id}&course=${params.coursesId}`}
                className="courseBtn nohover"
              >
                <span>კურსის დაწყება</span>
                <ReactSVG
                  className="courseIcon"
                  src="/images/arrow.svg"
                  alt=""
                />
              </Link>
            )}
          </div>

          {user?.user && (
            <div className="myProgress">
              <div className="myprogressHeader">
                <h2>ჩემი პროგრესი</h2>
                <div>
                  თავიდან დაწყება
                  <ReactSVG src="/images/courses/repeat.svg" />
                </div>
              </div>
              <div className="courseProgressBlock">
                <div className="courseProgressBar"></div>
                <div className="courseProgress" style={{ width: "50%" }}></div>
                <div className="progressPercents" style={{ marginLeft: "49%" }}>
                  52%
                </div>
                <div className="nextStepprogress" style={{ marginLeft: "99%" }}>
                  <span></span>
                  <div className="hndr">100%</div>
                </div>
              </div>
            </div>
          )}

          <div className="courseAboutSt">
            <button
              className={`${state === "კურსის შესახებ" && "courseActiveBtn"}`}
              onClick={() => setState("კურსის შესახებ")}
            >
              კურსის შესახებ
            </button>
            <button
              className={`${state === "სილაბუსი" && "courseActiveBtn"}`}
              onClick={() => setState("სილაბუსი")}
            >
              სილაბუსი
            </button>
          </div>

          <div
            className={`aboutCourse ${
              state === "კურსის შესახებ" && "activeCrsTab"
            }`}
          >
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: course?.overview }}
            ></div>
            <div className="courseStats">
              {/* <div className="studentsCr">
                <ReactSVG src="/images/courses/students.svg" />
              </div>
              <div className="timesCr">
                <ReactSVG src="/images/courses/hours.svg" />
              </div> */}
            </div>
          </div>
          <div
            className={`aboutSilabus ${state === "სილაბუსი" && "activeCrsTab"}`}
          >
            <div className="coursePieces">
              <div className="coursePieceList">
                {lessons?.length &&
                  lessons?.map((e, i) => {
                    return (
                      <>
                        <div
                          className={`pieceItem ${
                            pieceItem == i && "pieceItemActivated"
                          }`}
                          onClick={() => {
                            if (pieceItem == i) setPieceItem(null);
                            else setPieceItem(i);
                          }}
                        >
                          <div className="pieceIndex">
                            {i < 9 ? `0${i + 1}` : i + 1}
                          </div>
                          <div>
                            <h3>{e.name}</h3>
                          </div>
                          <ReactSVG
                            src="/images/courses/dropArrow.svg"
                            className={`drpoArrowPice ${
                              pieceItem == i && "rotateArrow"
                            }`}
                          />
                        </div>
                        <div
                          className={`pieceDetails ${
                            pieceItem == i && "activeDetails"
                          }`}
                        >
                          {e.topics?.map((detail) => {
                            return (
                              <>
                                {
                                  <>
                                    <Link
                                      to={
                                        user?.user
                                          ? `/task?lesson=${e.id}&id=${detail.id}&course=${params.coursesId}`
                                          : `/task?lesson=${e.id}&id=${detail.id}&course=${params.coursesId}`
                                      }
                                    >
                                      <div className="projectPieceBlock">
                                        <div className="detailsImgSvg">
                                          {
                                            <ReactSVG
                                              src="/images/courses/lesson.svg"
                                              className="courseClass"
                                            />
                                          }
                                          {/* {
                                                                            detail.quiz && <ReactSVG 
                                                                                src="/images/courses/quiz.svg" 
                                                                                className="courseClass"
                                                                            />
                                                                        }
                                                                        {
                                                                            detail.project && <ReactSVG 
                                                                                src="/images/courses/project.svg" 
                                                                                className="courseClass"
                                                                            />
                                                                        } */}
                                        </div>
                                        <div>
                                          <h4>
                                            {detail?.type == "exercise" &&
                                              "გაკვეთილი"}
                                          </h4>
                                          <h3>{detail.name}</h3>
                                        </div>
                                        <ReactSVG
                                          src="/images/courses/readmore.svg"
                                          className="readmoreArrow"
                                        />
                                      </div>
                                    </Link>
                                  </>
                                }
                              </>
                            );
                          })}
                          {lessons?.length > 0 && Array.isArray(lessons) && (
                            <Link
                              to={`/task?lesson=${e?.id}&id=${e?.topics[0]?.id}&course=${params.coursesId}`}
                              className="dackeba"
                            >
                              დაწყება <ReactSVG src="/images/whitearrow.svg" />
                            </Link>
                          )}
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
