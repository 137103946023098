import '../../styles/components/footer.styles.scss'
import {ReactSVG} from 'react-svg';

export default function Footer({rulesOpen, policyOpen}) {
    return <>
        <div className="footer">
            <div className="copyright">
                <ReactSVG 
                    className="footerLogo" 
                    src="/images/footer/logo.svg" 
                />
                <span>ყველა უფლება დაცულია Ⓒ განათლების მართვის საინფორმაციო სისტემა  {new Date().getFullYear()}</span>
            </div>
            <div className="rulestPolicy">
                <li onClick={()=> policyOpen()}>კონფიდენციალურობის პოლიტიკა</li>
                <li onClick={()=> rulesOpen()}>წესები და პირობები</li>
            </div>
            <div className="contact">
                <h4>
                    <ReactSVG src="/images/footer/email.svg" />
                    <span className="mailContactUnderline">
                        pr@mes.gov.ge
                    </span>
                </h4>
                <h4>
                    <ReactSVG src="/images/footer/discord.svg" />
                    <span>
                        შემოგვიერთდი დისქორდ სერვერზე
                    </span>
                </h4>
            </div>
        </div>
    </>
}