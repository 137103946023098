import React, {useState, useMemo} from 'react';
import "../../../../styles/pages/user.styles.scss";
import Button from '../../../../components/inputs/button';
import { ReactSVG } from 'react-svg';
import UpdatePassword from '../../../../components/modals/user/password';
import PersonalUpdate from '../../../../components/modals/user/personal';
import UpdateNumber from '../../../../components/modals/user/number';
import { notification } from 'antd';
import 'antd/dist/antd.css';
import UpdateMail from '../../../../components/modals/user/mail';

let coursesData = [
    {
        id: 0,
        name:'ისწავლე HTML',
        length: 12,
        pass: 4,
    }
]

export default function MemberProfileView() {
    const [courseType, setCourseType] = useState('მიმდინარე');
    const [isEdit, setIsEdit] = useState(false);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [updateInfo, setUpdateInfo] = useState(false);
    const [updatePhone, setUpdatePhone] = useState(false);
    const [updateMailModal, setUpdateMail] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    return <>
        {contextHolder}
        {
            updatePassword && <UpdatePassword api={api} onClose={()=> setUpdatePassword(false)} /> 
        }
        {
            updateInfo && <PersonalUpdate api={api} onClose={()=> setUpdateInfo(false)} /> 
        }
        {
            updatePhone && <UpdateNumber api={api} onClose={()=> setUpdatePhone(false)} /> 
        }
        {
            updateMailModal && <UpdateMail onClose={()=> setUpdateMail(false)} />
        }
        <div className="user_profile">
            <div className="user_space">
                <div className="user_ul">
                    <div className="user_tool">
                        <div className="user_avatar">
                            <ReactSVG className="user_profile_avatar" src="/images/user/Avatars.svg" />
                        </div>
                        <div className="user_information">
                            <h2>საბა ჩიტაიშვილი</h2>
                            <h4>მოსწავლე</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="user_courses">
                <h1>ჩემი კურსები</h1>
                <div className="user_table">
                    <div className="user_table_titles">
                        <button className={`${courseType == 'მიმდინარე' && 'tab_active'}`} onClick={()=> setCourseType('მიმდინარე')}>მიმდინარე</button>
                        <button className={`${courseType == 'დასრულებული' && 'tab_active'}`} onClick={()=> setCourseType('დასრულებული')}>დასრულებული</button>
                        <button className={`${courseType == 'სერტიფიკატები' && 'tab_active'}`} onClick={()=> setCourseType('სერტიფიკატები')}>სერტიფიკატები</button>
                    </div>
                    <div className={`user_current_courses ${courseType == 'მიმდინარე' && 'user_activeTab'}`}>
                        {
                            coursesData?.map((course, key)=>(
                                <div className="user_current_course" key={key}>
                                    <div className="user_current_course_image">
                                        <img src="/images/user/courseimg.png" alt="" />
                                    </div>
                                    <div className="user_current_course_info">
                                        <h2>{course.name}</h2>
                                        <h3><ReactSVG src="/images/user/time.svg" /> {course.pass} დარჩენილი {course.length}-დან</h3>
                                    </div>
                                    <div style={{ width: `${course.pass * 100 / course.length}%` }} className="user_current_course_progress"></div>
                                </div>
                            ))
                        }
                    </div>
                    <div className={`user_done_courses ${courseType == 'დასრულებული' && 'user_activeTab'}`}>
                        {
                            coursesData?.map((course, key)=>(
                                <div className="user_current_course" key={key}>
                                    <div className="user_current_course_image">
                                        <img src="/images/user/js.png" alt="" />
                                    </div>
                                    <div className="user_current_course_info">
                                        <h2>{course.name}</h2>
                                        <h3><ReactSVG src="/images/user/done.svg" /> დასრულებული</h3>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className={`user_courses_certificates ${courseType == 'სერტიფიკატები' && 'user_activeTab'}`}>
                        {
                            coursesData?.map((course, key)=>(
                                <div className="user_current_course" key={key}>
                                    <div className="user_current_course_image">
                                        <img src="/images/courses/certificate.png" alt="" />
                                    </div>
                                    <div className="user_current_course_info">
                                        <h2>{course.name}</h2>
                                        <h3>სერტიფიკატი</h3>
                                        <div className="user_certificat_btns">
                                            <button>
                                                გადმოწერა
                                                <ReactSVG src="/images/user/download.svg" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}