import '../../styles/components/inputs/button.styles.scss';

export default function Button({
    className,
    onClick,
    children
}) {
    return <>
        <button 
            className={`button ${className}`}
            onClick={onClick && onClick}
        >
            {children}
        </button>
    </>
}