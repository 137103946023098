import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit'
import userReducer from './userSlice'

export default configureStore({
  reducer: combineReducers({
    user: userReducer,
  }),
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})