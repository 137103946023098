import Splitter, { SplitDirection } from '@devbookhq/splitter'
import React, { useEffect, useState } from 'react';
import Editor from '../../components/editor';
import { ReactSVG } from 'react-svg';
import Modal from '../../components/modals/auth/modal';
import Button from '../../components/inputs/button';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import '../../styles/pages/practice.styles.css';

export default function Practice() {
    const [editorLangs, setEditorLangs] = useState ({
        html: '',
        css: '',
        js: ''
    })
    const [sizes, setSizes] = useState([1/3 * 100, 1/3 * 100, 1/3 * 100]);
    const [project, setProject] = useState({
        name: 'უსახელო პროექტი',
        isOpen: false
    })
    const [share, setShare] = useState({
        isShare: false,
        link: 'https://codepen.io/zhaojie/pen/zYOZOqB'
    });
    const [srcDoc, setSrcDoc] = useState('');

    function handleResize(gutterIdx, allSizes) {
        setSizes(allSizes);
    }

    function tabResizer(z, y) {
        return setSizes((prev)=>{
            let fill = prev.map((e,i)=> {
            if(i == z){
                return 6
            }else if(e > 6){
                return e + sizes[2] // could be 'y'
            }
            })
            return fill
        })
    }

    function saveCode() {
        return setSrcDoc(`
            <!doctype html>
            <head>
                <style>${editorLangs.css}</style>
            </head>
            <body>${editorLangs.html}</body>
            <script>${editorLangs.js}</script>
            </html>
        `)
    }

    //triggering browser resize event for change ace editor size
    useEffect(()=> {
        window.dispatchEvent(new Event('resize'))
    },[sizes])

    return <>
        {
            share.isShare &&
            <Modal 
                className="share_modal_open"
                onClose={()=> setShare((e)=> ({...e, isShare: false}))}
            >
                <div className="share_modal">
                    <div className="share-header">
                        <ReactSVG src="/images/practice/shareproject.svg" />
                        <h3>გააზიარე პროექტი</h3>
                    </div>
                    <div className="project-link-copy">
                        <span>{share.link}</span>
                        <CopyToClipboard 
                            text={share.link}
                            // onCopy={() => some function }
                        >
                            <button>
                                <ReactSVG src="/images/practice/copy.svg" />
                            </button>
                        </CopyToClipboard>
                    </div>
                    <Button 
                        className="closeBtnForShare"  
                        onClick={()=> setShare((e)=> ({...e, isShare: false}))}  
                    >
                        დახურვა
                    </Button>
                </div>
            </Modal>
        }
        <div>
            <div className="editors-tool">
                <div className="editor-name">
                    <ReactSVG src="/images/practice/project.svg" />
                    {
                        project.isOpen ? 
                            <div className='edit-project'>
                                <input 
                                    type="text" 
                                    value={project.name} 
                                    onChange={(e)=> setProject((prev)=>({...prev, name: e.target.value}))} 
                                    placeholder="პროექტის სახელი"
                                />
                                <ReactSVG 
                                    src="/images/practice/save.svg" 
                                    onClick={()=>{
                                        setProject({name: project.name, isOpen: false});
                                    }}
                                />
                            </div> : 
                            <span>{project.name ? project.name : 'უსახელო პროექტი'}</span>
                    }
                    {
                        !project.isOpen &&  
                        <ReactSVG 
                            src="/images/practice/edit.svg" 
                            onClick={()=> setProject((prev)=> ({...prev, isOpen: true}))} 
                        />
                    }
                </div>
                <div className="editors-toolBtns">
                    {/* <button>
                        <ReactSVG src="/images/practice/download.svg" />
                        გადმოწერე .zip
                    </button>
                    <button onClick={()=> setShare((e)=> ({...e, isShare:true}))}>
                        <ReactSVG src="/images/practice/share.svg" />
                        გააზიარე
                    </button> */}
                    <button onClick={()=> saveCode()}>
                        <ReactSVG src="/images/practice/play.svg" />
                        გაშვება
                    </button>
                </div>
            </div>
            <div className="editors-tab">
                <Splitter
                    direction={SplitDirection.Horizontal}
                    minWidths={[75, 75, 75]}
                    onResizeFinished={handleResize}
                    initialSizes={sizes}
                >
                    <div className="editor-content">
                        {
                        sizes[0] > 8 ? 
                        <>
                            <div className="tab-editor">
                                <div className="tab-header">
                                    <img src="/images/practice/html.svg" alt="" />
                                    <h2>HTML</h2>
                                    <img 
                                        onClick={()=> {
                                            if(sizes[1] > 8 && sizes[2] > 8){
                                                tabResizer(0)                    
                                            }else if(sizes[1] > 8){
                                                setSizes([6, 100 - (6 + 6), 6])
                                            }else {
                                                setSizes([6, 6, 100 - (6 + 6)])
                                            }
                                        }}        
                                        className="close_tab"
                                        src="/images/practice/splitOf.svg" 
                                        alt="" 
                                    /> 
                                </div>
                                <div className="editor">
                                    <Editor 
                                        mode={'html'} 
                                        value={editorLangs.html} 
                                        onChange={(e)=> setEditorLangs((prev)=> ({...prev, html: e}))} 
                                    />
                                </div>
                            </div>
                        </>
                        : 
                        <div className="editorWhooleSize" onClick={()=> setSizes([100 - (6 + 6), 6, 6])}>
                            <div className="tab-header closed">
                                <img src="/images/practice/html.svg" alt="" />
                            </div>
                            <div className="arrow-whole">
                                <img 
                                    onClick={()=> setSizes([100 - (6 + 6), 6, 6])}
                                    src="/images/practice/splitOn.svg" 
                                    alt="" 
                                /> 
                            </div>
                        </div>
                        }
                    </div>
                    <div className="editor-content">
                        {
                        sizes[1] > 8 ? 
                        <>
                            <div className="tab-editor">
                                <div className="tab-header">
                                    <img src="/images/practice/css.svg" alt="" />
                                    <h2>CSS</h2>
                                    <img 
                                        onClick={()=> {
                                            if(sizes[0] > 8 && sizes[2] > 8){
                                                tabResizer(1)                    
                                            }else if(sizes[0] > 8){
                                                setSizes([100 - (6 + 6), 6, 6])
                                            }else {
                                                setSizes([6, 6, 100 - (6 + 6)])
                                            }
                                        }}
                                        className="close_tab"
                                        src="/images/practice/splitOf.svg" 
                                        alt="" 
                                    /> 
                                </div>
                                <div className="editor">
                                    <Editor 
                                        mode={'css'} 
                                        value={editorLangs.css} 
                                        onChange={(e)=> setEditorLangs((prev)=> ({...prev, css: e}))} 
                                    />
                                </div>
                            </div>
                        </>
                        :   <div className="editorWhooleSize" onClick={()=> setSizes([6, 100 - (6 + 6), 6])}>
                                <div className="tab-header closed">
                                    <img src="/images/practice/css.svg" alt="" />
                                </div>
                                <div className="arrow-whole">
                                    <img 
                                        onClick={()=> setSizes([6, 100 - (6 + 6), 6])}
                                        src="/images/practice/splitOn.svg" 
                                        alt="" 
                                    /> 
                                </div>
                            </div>
                        }
                    </div>
                    <div className="editor-content">
                    {
                        sizes[2] > 8 ? 
                        <>
                            <div className="tab-editor">
                                <div className="tab-header">
                                    <img src="/images/practice/js.svg" alt="" />
                                    <h2>JS</h2>
                                    <img 
                                        onClick={()=> {
                                            if(sizes[0] > 8 && sizes[1] > 8){
                                                tabResizer(2)                     
                                            }else if(sizes[0] > 8){
                                                setSizes([100 - (6 + 6), 6, 6])
                                            }else {
                                                setSizes([6, 100 - (6 + 6), 6])
                                            }
                                        }}
                                        className="close_tab"
                                        src="/images/practice/splitOf.svg" 
                                        alt="" 
                                    /> 
                                </div>
                                <div className="editor">
                                    <Editor 
                                        mode={'javascript'} 
                                        value={editorLangs.js} 
                                        onChange={(e)=> setEditorLangs((prev)=> ({...prev, js: e}))} 
                                    />
                                </div>
                            </div>
                        </>
                        :   <div className="editorWhooleSize" onClick={()=> setSizes([6, 6, 100 - (6 + 6),])}>
                                <div className="tab-header closed">
                                    <img src="/images/practice/js.svg" alt="" />
                                </div>
                                <div className="arrow-whole">
                                    <img 
                                        onClick={()=> setSizes([6, 6, 100 - (6 + 6),])}
                                        src="/images/practice/splitOn.svg" 
                                        alt="" 
                                    /> 
                                </div>
                            </div>
                        }
                    </div>
                </Splitter>
            </div>
        </div>
        <div className='result'>
            <iframe
                srcDoc={srcDoc}
                title="output"
                sandbox="allow-scripts"
                frameBorder="0"
                width="100%"
                height="100%"
            />
        </div>
    </>
}