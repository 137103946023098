import '../../styles/components/modals/modal.styles.scss';
import {ReactSVG} from 'react-svg';

export default function Modal({
    title,
    classNames,
    onClose,
    children
}) {
    return <>
        <div className="modalBg" onClick={()=> onClose()}></div>
        <div className={`modal ${classNames}`}>
            <div className="modalHeader">
                <h2>{title}</h2>
                <span onClick={()=> onClose()}>
                    <ReactSVG src="/images/close.svg" />
                </span>
            </div>
            <div className="content">
                {children}
            </div>
        </div>
    </>
} 