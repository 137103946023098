import React, { useState } from 'react';
import Button from '../inputs/button';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import '../../styles/components/header.styles.scss';
import { useSelector } from 'react-redux';

export default function Header({setModalOpen, loginOpen, user}) {
    const [burger, setBurger] = useState(false);

    function logout() {
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('access_token')

        window.location.reload(false);
    }

    return <>
        {
            burger && <ResponsiveBurgerMenu setBurger={setBurger} setModalOpen={setModalOpen} />
        }
        <div className="header">
            <Link to="/">
                <div className="logo">
                    <img src="/images/header/logo.svg" alt="logo" />
                </div>
            </Link>
            <ReactSVG onClick={()=> setBurger(true)} className="burgerMenu" src="/images/header/burger.svg" />
            <div className="nav">
                <ul>
                    {/* <li>
                        <Link to="/">
                            კურსები
                        </Link>
                    </li> */}
                    <li onClick={()=> setModalOpen((state)=>!state)}>
                        <Link to="/">
                            პროექტის შესახებ
                        </Link>
                    </li>
                    <li>
                        <Link to="/practice">
                            ივარჯიშე
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="user-tool">
                {/* <div className="search">
                    <ReactSVG 
                        className='searchBtn'
                        src="images/header/search.svg" 
                    />
                </div> */}
                {/* {
                    user?.user ? <Link to={`user/profile`}><div className='auth_user_block'>
                        <ReactSVG src="/images/user/Avatars.svg" />
                        <div>
                            <h3>{user?.user.username}</h3>
                            <h4>{user?.user.role}</h4>
                        </div>
                        <button onClick={()=> logout()}>Log out</button>
                    </div></Link> : <div className='login'>
                    <Button className="loginBtn" onClick={()=> loginOpen()}>
                        <span>ავტორიზაცია</span>
                        <ReactSVG 
                            className="loginIcon"
                            src="images/header/login.svg" 
                        />
                    </Button>
                </div>
                } */}
            </div>
        </div>
    </>
}

export function ResponsiveBurgerMenu({setBurger, setModalOpen}) {
    return <>
        <div onClick={()=> setBurger(false)} className="responsive_menu_wrapper"></div>
        <div className="responsive_menu_bg">
            <div className="responsive_menu_header">
                <img src="/images/header/logo.svg" alt="logo" />
                <ReactSVG onClick={()=> setBurger(false)} src="/images/close.svg" />
            </div>
            <div className="responsive_nav">
                <ul>
                    <li onClick={()=> {setBurger(false);setModalOpen((state)=>!state)}}>
                        <Link to="#">
                            პროექტის შესახებ
                            <ReactSVG src="/images/nextarrow.svg" />
                        </Link>
                    </li>
                    <li onClick={()=> {setBurger(false)}}>
                        <Link to="/practice">
                            ივარჯიშე
                            <ReactSVG src="/images/nextarrow.svg" />
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    </>
}   