import {useEffect, useRef} from 'react';

import AceEditor from "react-ace";
import 'brace/mode/html';
import 'brace/mode/css';
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-monokai"
import "ace-builds/src-noconflict/ext-language_tools";
import 'ace-builds/webpack-resolver';

export default function Editor({
    mode,
    value,
    onChange,
}) {
    return (
        <AceEditor
            mode={mode}
            theme='monokai'
            value={value}
            onChange={onChange}
            name="UNIQUE_ID_OF_DIV"
            editorProps={{ $blockScrolling: true }}
            showError={true}
            setOptions={{
                wrap: true,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: false,
                showGutter: true,
                displayIndentGuides:true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 1,
                fontSize: 16
            }}
      />  
    );
}