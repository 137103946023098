import{ useState } from 'react';
import { ReactSVG } from 'react-svg';
import '../../styles/components/inputs/input.styles.scss';

export default function Input({
    title,
    type = 'text',
    classNames,
    value = '',
    onChange = null,
    placeholder,
    action = null,
    actionClass = '',
    ...options
}) {
    const [passType, setPassType] = useState(type);

    return <>
        <div className='input'>
            <div className="inputTitle">{title}</div>
            <div className="inputBlock">
                {
                    action && <ReactSVG className={`inputImg ${actionClass}`} src={action} />
                }
                <input 
                    className={`defaultInput ${classNames}`}
                    type={passType} 
                    value={value} 
                    onChange={(e)=> onChange(e.target.value)}
                    placeholder={placeholder}
                    {...options}
                /> 
                {
                    type == "password" && (passType == 'password' ? 
                        <button className="inpBtn show" onClick={()=> setPassType('text')}>
                            <ReactSVG src="/images/auth/show.svg" />
                        </button> : 
                        <button className="inpBtn" onClick={()=> setPassType('password')}>
                            <ReactSVG src="/images/auth/hide.svg" />
                        </button>) 
                }
            </div>
        </div>
    </>
}