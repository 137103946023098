import React, {useState} from 'react';
import Modal from './modal';
import Check from './check';
import Input from '../../inputs/input';
import Select from '../../inputs/select';
import '../../../styles/components/auth/registration.styles.scss';
import Button from '../../inputs/button';
import { ReactSVG } from 'react-svg';
import axios from 'axios';

let regions = [
    {
        label: 'თბილისი',
        value: 'თბილისი',
    },
    {
        label: 'აფხაზეთი',
        value: 'აფხაზეთი',
    },
    {
        label: 'აჭარა',
        value: 'აჭარა',
    },
    {
        label: 'გურია',
        value: 'გურია',
    },
    {
        label: 'იმერეთი',
        value: 'იმერეთი',
    },
    {
        label: 'კახეთი',
        value: 'კახეთი',
    },
    {
        label: 'მცხეთა-მთიანეთი',
        value: 'მცხეთა-მთიანეთი',
    },
    {
        label: 'სამეგრელო-ზემო სვანეთი',
        value: 'სამეგრელო-ზემო სვანეთი',
    },
    {
        label: 'რაჭა-ლეჩხუმი და ქვემო სვანეთი',
        value: 'რაჭა-ლეჩხუმი და ქვემო სვანეთი',
    },
    {
        label: 'სამცხე-ჯავახეთი',
        value: 'სამცხე-ჯავახეთი',
    },
    {
        label: 'ქვემო ქართლი',
        value: 'ქვემო ქართლი',
    },
    {
        label: 'შიდა ქართლი',
        value: 'შიდა ქართლი',
    }
]

export default function Registration({
    onClose
}) {
    const [student, setStudent] = useState({
        username:'',
        name: '',
        lastname: '',
        municipaly: '',
        school: '',
        class: null,
    })
    const [lector, setLector] = useState({
        username:'',
        name: '',
        lastname: '',
        municipaly: '',
        school: '',
    })
    const [info, setInfo] = useState({
        mail:'',
        phone: '',
        password: '',
        repassword:''
    })
    const [validation, setValidation] = useState(false);

    const [backValidation, setBackValidation] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    const [step, setStep] = useState(1);

    const [status, setStatus] = useState('მოსწავლე');

    async function register() {
        let test = status == 'მოსწავლე'; 
        let requestbody ={
            "username":  test ? student.username : lector.username,
            "first_name": test ? student.name : lector.name, 
            "last_name": test ? student.lastname : lector.lastname,
            "email": info.mail,
            "password": info.password,
            "region": test ? student.municipaly : lector.municipaly,
            "phone": info.phone,
            "school": test ? student.school : lector.school,
            "grade": test ? student.class : null,
            "role_id": 1
        }
        try {
            const response = await axios.post('auth/register', requestbody);
            
            if(response.status == 201){
                setStep(3)
            }else {
                setBackValidation(true)
                setValidationMessage(response.response.data.message)
            }
            return response 
        }catch(error) {
            console.log(error)
        }
    }

    return <>
        {
            step === 1 &&
            <Modal
                title="დარეგისტრირდი"
                onClose={()=> onClose()}
            >
                <div className="userStatus">
                    <button 
                        className={`statusBtn ${status == 'მოსწავლე' && 'activeStatusBtn'}`}
                        onClick={()=> {setValidation(false); setStatus('მოსწავლე')}}
                    >
                        მოსწავლე
                    </button>
                    <button
                        className={`statusBtn ${status == 'მასწავლებელი' && 'activeStatusBtn'}`}
                        onClick={()=> {setValidation(false); setStatus('მასწავლებელი')}}
                    >
                        მასწავლებელი
                    </button>
                </div>

                {
                    status == 'მოსწავლე' ? <>
                        <Input 
                            title="მომხმარებლის სახელი (username)"
                            value={student.username}
                            onChange={(e)=> setStudent((prev)=>({...prev, username: e}))}
                            placeholder="მომხმარებლის სახელი (username)"
                            classNames="regInput"
                            type="text"
                        />
                        <Input 
                            title="შენი სახელი"
                            value={student.name}
                            onChange={(e)=> setStudent((prev)=>({...prev, name: e}))}
                            placeholder="შენი სახელი"
                            classNames="regInput"
                            type="text"
                        />
                        <Input 
                            title="შენი გვარი"
                            value={student.lastname}
                            onChange={(e)=> setStudent((prev)=>({...prev, lastname: e}))}
                            placeholder="შენი გვარი"
                            classNames="regInput"
                            type="text"
                        />
                        <Select 
                            label="რეგიონი"
                            labelStyle="outside"
                            className={'selectInput'}
                            options={regions}
                            onChange={(value) => {
                                setStudent((prev)=> ({...prev, municipaly: value}));
                            }}
                            value={student.municipaly}
                        />
                        <Input 
                            title="სკოლა"
                            value={student.school}
                            onChange={(e)=> setStudent((prev)=>({...prev, school: e}))}
                            placeholder="სკოლა"
                            classNames="regInput"
                            type="text"
                        />
                        <Select 
                            label="კლასი"
                            labelStyle="outside"
                            className={'selectInput'}
                            options={[
                                { label: "7", value: "7" },
                                { label: "8", value: "8" },
                                { label: "9", value: "9" },
                                { label: "10", value: "10" },
                                { label: "11", value: "11" },
                                { label: "12", value: "12" },
                                ]}
                            onChange={(value) => {
                                setStudent((prev)=> ({...prev, class: value}));
                            }}
                            value={student.class}
                        />
                    </> : <>
                        <Input 
                            title="მომხმარებლის სახელი (username)"
                            value={lector.username}
                            onChange={(e)=> setLector((prev)=>({...prev, username: e}))}
                            placeholder="მომხმარებლის სახელი (username)"
                            classNames="regInput"
                            type="text"
                        />
                        <Input 
                            title="შენი სახელი"
                            value={lector.name}
                            onChange={(e)=> setLector((prev)=>({...prev, name: e}))}
                            placeholder="შენი სახელი"
                            classNames="regInput"
                            type="text"
                        />
                        <Input 
                            title="შენი გვარი"
                            value={lector.lastname}
                            onChange={(e)=> setLector((prev)=>({...prev, lastname: e}))}
                            placeholder="შენი გვარი"
                            classNames="regInput"
                            type="text"
                        />
                        <Select 
                            label="რეგიონი"
                            labelStyle="outside"
                            className={'selectInput'}
                            options={regions}
                            onChange={(value) => {
                                setLector((prev)=> ({...prev, municipaly: value}));
                            }}
                            value={lector.municipaly}
                        />
                        <Input 
                            title="სკოლა"
                            value={lector.school}
                            onChange={(e)=> setLector((prev)=>({...prev, school: e}))}
                            placeholder="სკოლა"
                            classNames="regInput"
                            type="text"
                        />
                    </>
                }

                {
                    validation && 
                    <div className="validation">
                        შეავსეთ ყველა ველი
                    </div>
                }

                <Button 
                    className="loginBtnAuth"
                    onClick={()=> {
                        if(status == 'მოსწავლე'){
                            if(!student.class || !student.lastname || !student.name || !student.municipaly || !student.username){
                                setValidation(true)
                            }else {
                                setStep(2)
                            }
                        }else {
                            console.log('this is ')
                            if(!lector.lastname || !lector.name || !lector.municipaly || !lector.username){
                                setValidation(true)
                            }else {
                                setStep(2)
                            }
                        }
                    }}
                >
                    <span>გაგრძელება</span>
                    <ReactSVG 
                        className="nextStep"
                        src="images/auth/nextStep.svg" 
                    />
                </Button>
            </Modal>
        }
        {
            step === 2 && 
            <Modal
                title="დარეგისტრირდი"
                onClose={()=> onClose()}
            >
                <Input 
                    title="ელფოსტა"
                    value={info.mail}
                    onChange={(e)=> setInfo((prev)=>({...prev, mail: e}))}
                    placeholder="ელფოსტა"
                    classNames="regInput"
                    type="text"
                />
                <Input 
                    title="ტელეფონის ნომერი"
                    value={info.phone}
                    onChange={(e)=> setInfo((prev)=>({...prev, phone: e}))}
                    placeholder="ტელეფონის ნომერი"
                    classNames="regInput"
                    type="text"
                />
                <Input 
                    title="პაროლი"
                    value={info.password}
                    onChange={(e)=> setInfo((prev)=>({...prev, password: e}))}
                    placeholder="პაროლი"
                    classNames="regInput"
                    type="password"
                />
                <Input 
                    title="გაიმეორე პაროლი"
                    value={info.repassword}
                    onChange={(e)=> setInfo((prev)=>({...prev, repassword: e}))}
                    placeholder="გაიმეორე პაროლი"
                    classNames="regInput"
                    type="password"
                />

                {
                    backValidation && 
                    <div className="validation">
                        {validationMessage}
                    </div>
                }

                <div className="regBtns">
                    <div 
                        className='backBtn'
                        onClick={()=> setStep(1)}
                    >
                        <ReactSVG src="images/auth/prev.svg" />
                        <span>უკან</span>
                    </div>
                    <Button 
                        className="registBtn"
                        onClick={()=>{
                            register()
                        }}
                    >
                        <span>რეგისტრაცია</span>
                        <ReactSVG 
                            className="nextStep"
                            src="images/auth/nextStep.svg" 
                        />
                    </Button>
                </div>
            </Modal>
        }
        {
            step === 3 &&
            <Check 
                onClose={()=> onClose()}
            >
                <div className="message">
                    <div className="imgMessage">
                        <ReactSVG src="/images/auth/checkMail.svg" />
                    </div>
                    <div className="messagetext">
                        <h3>შეამოწმე ელფოსტა</h3>
                        <span>
                            რეგისტრაციის დასასრულებლად მითითებულ ელ.ფოსტაზე გამოგიგზავნეთ შეტყობინება.
                        </span>
                    </div>
                    <Button 
                        className="close"
                        onClick={()=> onClose()}
                    >
                        <span>დახურვა</span>
                    </Button>
                </div>
            </Check>
        }
    </>
}