import '../../styles/components/modals/about.styles.scss';
import Modal from './modal';

export default function About({
    onClose
}) {
    return <>
        <Modal 
            title="პროექტის შესახებ"
            onClose={onClose}
        >
            <div className="about">
                <p>
                    ლორემ იპსუმ ტელეარხებმა ხიბლავს გაფორმებასა ჰყვებოდა, 
                    უდმურტეთიდან მკვახე ჰზიდოს წამოგვცდენოდა გარემოება დამეთმო. 
                    ოდისეის ტიალი ცხელის, ეგზოტიკურ ზღაპრებს მსხვერპლმა ჩაუკრა 
                    ამბავსაც დაიმორჩილა, ქუდმოუხდელი ბეტ ჯენზე უმის. მკვახე 
                    ესპანურის შევუფარდო, ჯჯაყო ჩაექსოვნენ, გაჩვარებული ფუღუროში 
                    უმის ჩაკვლის გოლიათური.
                </p>
                <p>
                    ლორემ იპსუმ ტელეარხებმა ხიბლავს გაფორმებასა ჰყვებოდა, 
                    უდმურტეთიდან მკვახე ჰზიდოს წამოგვცდენოდა გარემოება დამეთმო. 
                    ოდისეის ტიალი ცხელის, ეგზოტიკურ ზღაპრებს მსხვერპლმა ჩაუკრა 
                    ამბავსაც დაიმორჩილა, ქუდმოუხდელი ბეტ ჯენზე უმის. მკვახე 
                    ესპანურის შევუფარდო, ჯჯაყო ჩაექსოვნენ, გაჩვარებული ფუღუროში 
                    უმის ჩაკვლის გოლიათური.
                </p>
            </div>
            <div className="staff">
                <div className="staff_header">
                    <h2>გუნდი</h2>
                </div>
                <div className='staff_list'>
                    {
                        Array.from(new Array(4).entries()).map((person)=>{
                            return <>
                                <div className="staff_person">
                                    <h2>
                                        სანდრო ასათიანი
                                    </h2>
                                    <span>
                                        იდეის ავტორი
                                    </span>
                                </div>
                            </>
                        })
                    }
                </div>
            </div>
        </Modal>
    </>
}