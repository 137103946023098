import Splitter, { SplitDirection } from '@devbookhq/splitter'
import React, { useEffect, useState } from 'react';
import Editor from '../../components/editor';
import "../../styles/components/task/lesson.styles.scss";
import { ReactSVG } from 'react-svg';
export default function Lesson({data, topic, langs, tasks, dropdown, sentTask, currentTask}) {
    const [editorLangs, setEditorLangs] = useState ({})
    const [srcDoc, setSrcDoc] = useState('');
    const [sizes, setSizes] = useState([]);
    function handleResize(gutterIdx, allSizes) {
        setSizes(allSizes);
    }

    function tabResizer(z, y) {
        return setSizes((prev)=>{
            let fill = prev.map((e,i)=> {
            if(i == z){
                return 6
            }else if(e > 6){
                return e + sizes[2] // could be 'y'
            }
            })
            return fill
        })
    }

    //triggering browser resize event for change ace editor size
    useEffect(()=> {
        window.dispatchEvent(new Event('resize'))
    },[sizes])

    useEffect(()=>{
        if(langs.length == 1) {
            setSizes([60, 40])
        } else if(langs.length == 2){
            setSizes([40, 30, 30])
        }
    },[])

    useEffect(()=> {
        for(let i = 0; i < langs.length; i++){
            if(langs[i]=='html') {
                setEditorLangs((e)=> ({...e, html: currentTask?.html_solution}))
            }else {
                setEditorLangs((e)=> ({...e, css: currentTask?.css_solution}))
            }
        }
    }, [currentTask])

    function saveCode() {
        return setSrcDoc(`
            <!doctype html>
            <head>
                <style>${editorLangs.css}</style>
            </head>
            <body>${editorLangs.html}</body>
            <script>${editorLangs.js}</script>
            </html>
        `)
    }

    return <>
        <div className="btnToolForTask">
            {/* <div className="minishneba">
                <ReactSVG src="/images/task/minishneba.svg" />
                <div className="minishneba_tooltip">
                    მინიშნება
                </div>
            </div>
            <div className="axsna">
                <ReactSVG src="/images/task/axsna.svg" />
                <div className="axsna_tooltip">
                    ახსნა
                </div>
            </div>
            <button onClick={()=> saveCode()}>
                <ReactSVG src="/images/practice/play.svg" />
                გაშვება
            </button> */}
        </div>
        <div className="editors-tab">
            <Splitter
                direction={SplitDirection.Horizontal}
                minWidths={langs.length === 1 ? [75, 75] : [75, 75, 75]}
                onResizeFinished={handleResize}
                initialSizes={sizes}
            >
                <div className="editor-content">
                    {
                    sizes[0] > 8 ? 
                    <>
                        <div className="tab-editor">
                            <div className="task">
                                <div className="task_title">
                                    <div>
                                        <h3 style={{visibility: 'hidden'}}>სავარჯიშო</h3>
                                        <h2>{topic?.name}</h2>
                                    </div>
                                </div>
                                <div className="task_description">
                                    <div className="content" dangerouslySetInnerHTML={{__html: data?.theory}}></div>
                                    <div className="tasks">
                                        {
                                            tasks?.map((e, i)=> {
                                                return <>
                                                    <div className="taskHeader" onClick={()=> dropdown(e)}>
                                                        {
                                                            e.isDone == true ? 
                                                            <ReactSVG src="/images/task/success.svg" /> : 
                                                            <ReactSVG src="/images/task/pending.svg"/>
                                                        }
                                                        სავარჯიშო {i + 1}
                                                        {
                                                            e?.isOpen == true ? 
                                                            <ReactSVG className="dropdownicon" src="/images/task/dropdownactive.svg" />
                                                            :
                                                            <ReactSVG className="dropdownicon" src="/images/task/dropdown.svg" />
                                                        }
                                                    </div>
                                                    {
                                                        e?.isOpen == true && 
                                                        <div className="taskContent" dangerouslySetInnerHTML={{__html: e?.description}}></div>
                                                    }
                                                </>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    : 
                    <div className="editorWhooleSize" onClick={()=> {
                        if(langs.length == 2) {
                            setSizes([100 - (6 + 6), 6, 6])
                        } else {
                            setSizes([100 - 6, 6])
                        }
                    }}>
                        <div className="tab-header closed">
                            <ReactSVG src="/images/task/task.svg" />
                        </div>
                        <div className="arrow-whole">
                            <img 
                                onClick={()=> {
                                    if(langs.length == 2) {
                                        setSizes([100 - (6 + 6), 6, 6])
                                    } else {
                                        setSizes([100 - 6, 6])
                                    }
                                }}
                                src="/images/practice/splitOn.svg" 
                                alt="" 
                            /> 
                        </div>
                    </div>
                    }
                </div>
                {
                    langs.includes('html') && 
                    <div className="editor-content">
                        {
                        sizes[langs.findIndex(e=> e=='html') + 1] > 8 ? 
                        <>
                            <div className="tab-editor">
                                <div className="tab-header">
                                    <img src="/images/practice/html.svg" alt="" />
                                    <h2>HTML</h2>
                                    <img 
                                        onClick={()=> {
                                            if(langs.length == 2) {
                                                if(sizes[0] > 8 && sizes[2] > 8){
                                                    tabResizer(1)                    
                                                }else if(sizes[0] > 8){
                                                    setSizes([100 - (6 + 6), 6, 6])
                                                }else {
                                                    setSizes([6, 6, 100 - (6 + 6)])
                                                }
                                            } else {
                                                setSizes([100 - 6, 6])
                                            }
                                        }}
                                        className="close_tab"
                                        src="/images/practice/splitOf.svg" 
                                        alt="" 
                                    /> 
                                </div>
                                <div className="editor">
                                    <Editor 
                                        mode={'html'} 
                                        value={editorLangs.html} 
                                        onChange={(e)=> setEditorLangs((prev)=> ({...prev, html: e}))} 
                                    />
                                </div>
                            </div>
                        </>
                        :   <div className="editorWhooleSize" onClick={()=> {
                                if(langs.length == 2) {
                                    setSizes([6, 100 - (6 + 6), 6])
                                } else {
                                    setSizes([6, 100 - 6])
                                }
                            }}>
                                <div className="tab-header closed">
                                    <img src="/images/practice/html.svg" alt="" />
                                </div>
                                <div className="arrow-whole">
                                    <img 
                                        onClick={()=> {
                                            if(langs.length == 2) {
                                                setSizes([6, 100 - (6 + 6), 6])
                                            } else {
                                                setSizes([6, 100 - 6])
                                            }
                                        }}
                                        src="/images/practice/splitOn.svg" 
                                        alt="" 
                                    /> 
                                </div>
                            </div>
                        }

                        {/* <div className="tool_for_editor">
                            <button onClick={()=> sentTask(currentTask)} className="gashveba"><ReactSVG src="/images/task/gashveba.svg" />გაშვება</button>
                            <button style={{marginLeft: 'auto'}}><ReactSVG src="/images/task/amoxsna.svg" />ამოხსნა</button>
                            <button><ReactSVG src="/images/task/minishneba.svg" /></button>
                        </div> */}
                    </div>
                }
                {
                    langs.includes('css') && 
                    <div className="editor-content">
                    {
                        sizes[langs.findIndex(e=> e=='css') + 1] > 8 ? 
                        <>
                            <div className="tab-editor">
                                <div className="tab-header">
                                    <img src="/images/practice/css.svg" alt="" />
                                    <h2>CSS</h2>
                                    <img 
                                        onClick={()=> {
                                            if(langs.length == 2) {
                                                if(sizes[0] > 8 && sizes[1] > 8){
                                                    tabResizer(2)                     
                                                }else if(sizes[0] > 8){
                                                    setSizes([100 - (6 + 6), 6, 6])
                                                }else {
                                                    setSizes([6, 100 - (6 + 6), 6])
                                                }
                                            } else {
                                                setSizes([100 - 6, 6])
                                            }
                                        }}
                                        className="close_tab"
                                        src="/images/practice/splitOf.svg" 
                                        alt="" 
                                    /> 
                                </div>
                                <div className="editor">
                                    <Editor 
                                        mode={'css'} 
                                        value={editorLangs.css} 
                                        onChange={(e)=> setEditorLangs((prev)=> ({...prev, css: e}))} 
                                    />
                                </div>
                            </div>
                        </>
                        :   <div className="editorWhooleSize" onClick={()=> {
                                if(langs.length == 2) {
                                    setSizes([6, 6, 100 - (6 + 6)])
                                } else {
                                    setSizes([6, 100 - 6])
                                }
                            }}>
                                <div className="tab-header closed">
                                    <img src="/images/practice/css.svg" alt="" />
                                </div>
                                <div className="arrow-whole">
                                    <img 
                                        onClick={()=> {
                                            if(langs.length == 2) {
                                                setSizes([6, 6, 100 - (6 + 6)])
                                            } else {
                                                setSizes([6, 100 - 6])
                                            }
                                        }}
                                        src="/images/practice/splitOn.svg" 
                                        alt="" 
                                    /> 
                                </div>
                            </div>
                        }
                    </div>
                }
            </Splitter>
        </div>
        {/* <div className='result'>
            <iframe
                srcDoc={srcDoc}
                title="output"
                sandbox="allow-scripts"
                frameBorder="0"
                width="100%"
                height="100%"
            />
        </div> */}
    </>
}