import React, { useState, useMemo } from "react";
import "../../../styles/pages/user.styles.scss";
import Button from "../../../components/inputs/button";
import { ReactSVG } from "react-svg";
import UpdatePassword from "../../../components/modals/user/password";
import PersonalUpdate from "../../../components/modals/user/personal";
import UpdateNumber from "../../../components/modals/user/number";
import { notification } from "antd";
import "antd/dist/antd.css";
import UpdateMail from "../../../components/modals/user/mail";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";

let coursesData = [
  {
    id: 0,
    name: "ისწავლე HTML",
    length: 12,
    pass: 4,
  },
];

export default function MemberProfile() {
  const [courseType, setCourseType] = useState("მიმდინარე");
  const [isEdit, setIsEdit] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [updateInfo, setUpdateInfo] = useState(false);
  const [updatePhone, setUpdatePhone] = useState(false);
  const [updateMailModal, setUpdateMail] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [userData, setUserData] = useState({});
  const [userCourses, setUserCourses] = useState([]);
  const user = useSelector((state) => state.user);

  const getData = async (url) => {
    try {
      const response = await axios(url);

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user?.user?.id) {
      getData(`/user/${user?.user?.id}`).then((res) => setUserData(res.data));

      getData(`/user/courses?user_id=${user?.user?.id}`).then((res) =>
        setUserCourses(res.data)
      );
    }
  }, [user?.user?.id]);

  return (
    <>
      {contextHolder}
      {updatePassword && (
        <UpdatePassword api={api} onClose={() => setUpdatePassword(false)} />
      )}
      {updateInfo && (
        <PersonalUpdate
          data={userData}
          id={user?.user?.id}
          api={api}
          onClose={() => setUpdateInfo(false)}
        />
      )}
      {updatePhone && (
        <UpdateNumber
          phone={userData?.phone}
          id={user?.user?.id}
          api={api}
          onClose={() => setUpdatePhone(false)}
        />
      )}
      {updateMailModal && user?.user?.id && (
        <UpdateMail
          email={userData?.email}
          id={user?.user?.id}
          onClose={() => setUpdateMail(false)}
        />
      )}
      <div className="user_profile">
        <div className="user_space">
          <div className="user_ul">
            <div className="user_tool">
              <div className="user_avatar">
                <ReactSVG
                  className="user_profile_avatar"
                  src="/images/user/Avatars.svg"
                />
                <ReactSVG
                  className="user_edit_icon"
                  src="/images/user/userEdit.svg"
                />
              </div>
              <div className="user_information">
                <h2>{userData?.first_name}</h2>
                <h4>მოსწავლე</h4>
              </div>
            </div>
            <div className="user_edit">
              <Button
                className="user_edit_icon"
                onClick={() => setIsEdit(!isEdit)}
              >
                რედაქტირება
                <ReactSVG src="/images/user/editicon.svg" />
              </Button>
              <Button className="user_logout_icon">
                გასვლა
                <ReactSVG src="/images/user/logout.svg" />
              </Button>

              <div
                className={`user_edit_block ${
                  isEdit && "active_user_edit_block"
                }`}
              >
                <li
                  onClick={() => {
                    setIsEdit(false);
                    setUpdatePassword(true);
                  }}
                >
                  პაროლის შეცვლა
                </li>
                <li
                  onClick={() => {
                    setIsEdit(false);
                    setUpdateMail(true);
                  }}
                >
                  ელფოსტის შეცვლა
                </li>
                <li
                  onClick={() => {
                    setIsEdit(false);
                    setUpdateInfo(true);
                  }}
                >
                  პირადი ინფორმაციის შეცვლა
                </li>
                <li
                  onClick={() => {
                    setIsEdit(false);
                    setUpdatePhone(true);
                  }}
                >
                  ტელეფონის ნომრის შეცვლა
                </li>
              </div>
            </div>
          </div>
        </div>
        <div className="user_courses">
          <h1>ჩემი კურსები</h1>
          <div className="user_table">
            <div className="user_table_titles">
              <button
                className={`${courseType == "მიმდინარე" && "tab_active"}`}
                onClick={() => setCourseType("მიმდინარე")}
              >
                მიმდინარე
              </button>
              <button
                className={`${courseType == "დასრულებული" && "tab_active"}`}
                onClick={() => setCourseType("დასრულებული")}
              >
                დასრულებული
              </button>
              <button
                className={`${courseType == "სერტიფიკატები" && "tab_active"}`}
                onClick={() => setCourseType("სერტიფიკატები")}
              >
                სერტიფიკატები
              </button>
            </div>
            <div
              className={`user_current_courses ${
                courseType == "მიმდინარე" && "user_activeTab"
              }`}
            >
              {userCourses?.map((course, key) => (
                <div className="user_current_course" key={key}>
                  <div className="user_current_course_image">
                    <img src="/images/user/courseimg.png" alt="" />
                  </div>
                  <div className="user_current_course_info">
                    <h2>{course.name}</h2>
                    <h3>
                      <ReactSVG src="/images/user/time.svg" /> {course.pass}{" "}
                      დარჩენილი {course.length}-დან
                    </h3>
                    <Button className="user_current_course_btn">
                      გაგრძელება
                      <ReactSVG src="/images/user/cont.svg" />
                    </Button>
                  </div>
                  <div
                    style={{ width: `${(course.pass * 100) / course.length}%` }}
                    className="user_current_course_progress"
                  ></div>
                </div>
              ))}
            </div>
            <div
              className={`user_done_courses ${
                courseType == "დასრულებული" && "user_activeTab"
              }`}
            >
              {coursesData?.map((course, key) => (
                <div className="user_current_course" key={key}>
                  <div className="user_current_course_image">
                    <img src="/images/user/js.png" alt="" />
                  </div>
                  <div className="user_current_course_info">
                    <h2>{course.name}</h2>
                    <h3>
                      <ReactSVG src="/images/user/done.svg" /> დასრულებული
                    </h3>
                    <Button className="user_current_course_btn">
                      ვრცლად
                      <ReactSVG src="/images/user/more.svg" />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={`user_courses_certificates ${
                courseType == "სერტიფიკატები" && "user_activeTab"
              }`}
            >
              {coursesData?.map((course, key) => (
                <div className="user_current_course" key={key}>
                  <div className="user_current_course_image">
                    <img src="/images/courses/certificate.png" alt="" />
                  </div>
                  <div className="user_current_course_info">
                    <h2>{course.name}</h2>
                    <h3>სერტიფიკატი</h3>
                    <div className="user_certificat_btns">
                      <button>
                        გადმოწერა
                        <ReactSVG src="/images/user/download.svg" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
