import { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import "../../../styles/pages/task.styles.scss";
import Lesson from "../../../components/task/lesson";
import Quiz from "../../../components/task/quiz";
import Project from "../../../components/task/project";
import Success from "../../../components/task/success";
import Theory from "../../../components/task/theory";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { GET_DATA } from "../../../utils/request";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useTopic from "../../../hooks/useTopic";
import { useLesson } from "../../../hooks/useLesson";
import { useExercise } from "../../../hooks/useExcercise";
import { useWindowSize } from "../../../utils/useWindowSize";
import { DeviceError } from "../../../App";

export default function Task() {
  const { width } = useWindowSize();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  let id = searchParams.get("id"),
    lessonId = searchParams.get("lesson"),
    courseId = searchParams.get("course");

  const { topic } = useTopic(id);
  const { lesson } = useLesson(lessonId);
  const { exercise } = useExercise(id);
  const [taskIndex, setTaskIndex] = useState(0);
  const [step, setStep] = useState(0);
  const taskObject = [
    {
      type: "theory",
      title: "შექმენი შენი პირველი HTML დოკუმენტი",
      description: `HTML - Hypertext Markup Language, ანუ ჰიპერტექსტის ნიშნულების ენა, რომელიც 1993 წელს შექმნა ინგლისელმა მეცნიერმა ტიმ ბერნერს ლიმ (იგი შესულია ცოცხალი გენიოსების მსოფლიო ასეულში).
                ზოგადად რომ წარმოვიდგინოთ ვებ-გვერდის მუშაობა არის ერთგვარი დიალოგი მომხმარებელსა და სერვერს შორის , გვერდის საშუალებით იგზავნება მოთხოვნა სერვერზე, იქიდან კი ბრუნდება პასუხი ტექსტის სახით.`,
      languages: ["html", "css"],
    },
    {
      type: "code",
      title: "შექმენი შენი პირველი HTML დოკუმენტი",
      description: `HTML - Hypertext Markup Language, ანუ ჰიპერტექსტის ნიშნულების ენა, რომელიც 1993 წელს შექმნა ინგლისელმა მეცნიერმა ტიმ ბერნერს ლიმ (იგი შესულია ცოცხალი გენიოსების მსოფლიო ასეულში).
                ზოგადად რომ წარმოვიდგინოთ ვებ-გვერდის მუშაობა არის ერთგვარი დიალოგი მომხმარებელსა და სერვერს შორის , გვერდის საშუალებით იგზავნება მოთხოვნა სერვერზე, იქიდან კი ბრუნდება პასუხი ტექსტის სახით.`,
      languages: ["html", "css"],
    },
    {
      type: "quiz",
      title: "ქვიზი - HTML ელემენტები და სტრუქტურა",
      description: `თუკი მომხმარებელს არ აქვს დაწყებული კურსი, შიდა გვერდზე შესვლისას გამოჩნდება ღილაკი „დაწყება“. ღილაკზე დაჭერისას, თუკი მომხმარებელი უკვე რეგისტრირებულია და შესულია სისტემაში, შეძლებს კურსის დაწყებას, წინააღმდეგ შემთხვევაში, გადავა რეგისტრაციის/ავტორიზაციის გვერდზე.`,
    },
    {
      type: "project",
      title: "შექმენი მარტივი HTML გვერდი",
      description: `HTML - Hypertext Markup Language, ანუ ჰიპერტექსტის ნიშნულების ენა, რომელიც 1993 წელს შექმნა ინგლისელმა მეცნიერმა ტიმ ბერნერს ლიმ (იგი შესულია ცოცხალი გენიოსების მსოფლიო ასეულში).
                ზოგადად რომ წარმოვიდგინოთ ვებ-გვერდის მუშაობა არის ერთგვარი დიალოგი მომხმარებელსა და სერვერს შორის , გვერდის საშუალებით იგზავნება მოთხოვნა სერვერზე, იქიდან კი ბრუნდება პასუხი ტექსტის სახით.`,
    },
  ];
  const [currentTopic, setCurrentTopic] = useState({});
  const [currentIndex, setCurrentIndex] = useState(null);
  const [nextTopic, setNextTopic] = useState({});
  const [prevTopic, setPrevTopic] = useState({});
  const [pages, setPages] = useState(1);
  const [_tasks, setTasks] = useState([]);
  const [currentTask, setCurrentTask] = useState({});

  //dropdown function
  function dropdown(item) {
    setTasks((e) => {
      const modifyarray = e.map((x) => {
        if (x.id == item.id) {
          return { ...x, isOpen: !x.isOpen };
        } else {
          return { ...x, isOpen: false };
        }
      });
      return modifyarray;
    });
  }

  //task success function \f
  function sentTask(item) {
    const nexttask = _tasks.findIndex((e) => e.id == item.id);
    console.log(currentTask, "next");
    setTaskIndex(e=> e + 1);
    setTasks((e) => {
      const modifyarray = e.map((x, i) => {
        if (x.id == item.id) {
          return { ...x, isOpen: false, isDone: true, current: false };
        } else if (x.id == _tasks[nexttask + 1]?.id) {
          return { ...x, isOpen: true, current: true};
        } else {
          return { ...x, isOpen: false };
        }
      });
      return modifyarray;
    });
    setCurrentTask(_tasks[nexttask + 1]);
  }

  function reverse(item) {
    setTaskIndex(e=> e - 1);
    let prevTask = _tasks[taskIndex - 1]
    setCurrentTask(prevTask)
    setTasks((e) => {
      const modifyarray = e.map((x, i) => {
        if (x.id == prevTask?.id) {
          return { ...x, isOpen: true, isDone: false, current: true };
        } else if(i < taskIndex){
          return { ...x, isOpen: false, isDone: true, current: false };
        } else {
          return { ...x, isOpen: false, isDone: false, current: false };
        }
      });
      return modifyarray;
    });
  }

  console.log(taskIndex, 'task index')

  useEffect(() => {
    setCurrentTopic(topic);
    //find current tipic index
    const findCourseIndex = lesson?.topics.findIndex(
      (e) => e?.name === topic?.name
    );
    setCurrentIndex(findCourseIndex);
    setStep(findCourseIndex);

    if (findCourseIndex !== -1) {
      let nextTopic = lesson?.topics?.filter(
        (e, i) => i == findCourseIndex + 1
      )[0];
      let prevTopic = lesson?.topics?.filter(
        (e, i) => i == findCourseIndex - 1
      )[0];
      setPrevTopic(prevTopic);
      setNextTopic(nextTopic);
    } else {
      setNextTopic(null);
    }
  }, [lesson, topic]);

  useEffect(() => {
    if (Array.isArray(exercise)) {
      GET_DATA(`task/?exercise_id=${exercise[pages - 1]?.id}`).then((e) => {
        if (Array.isArray(e)) {
          let modifyTasks = e?.map((e, i) => {
            if (i == 0) {
              setCurrentTask({
                ...e,
                isDone: false,
                isOpen: true,
                current: true,
              });
              return { ...e, isDone: false, isOpen: true, current: true };
            } else {
              return { ...e, isDone: false, isOpen: false, current: false };
            }
          });
          setTasks(modifyTasks);
        }
      });
    }
  }, [exercise, pages]);

  return width < 834 ? (
    <DeviceError />
  ) : (
    <>
      <div className="task_container">
        <div className="task_header">
          <div className="task_backtothecourse">
            {step !== taskObject.length && (
              <>
                <ReactSVG className="task_arrow" src="/images/arrow.svg" />
                <Link to={`/courses/${lesson?.course[0].id}`}>
                  <span>კურსის გვერდზე დაბრუნება</span>
                </Link>
              </>
            )}
          </div>
          <div className="task_course">
            <h3>{lesson && lesson?.course[0]?.name}</h3>
            <span>/</span>
            <h4>{lesson?.name}</h4>
            {/* <ReactSVG className="task_curseArrow" src="/images/arrow.svg" /> */}
          </div>
          <div className="task_taskTool"></div>
        </div>
        <div className="task_content">
          {lesson ? (
            step !== lesson?.topics?.length ? (
              lesson?.topics?.map((task, index) => {
                if (index == step) {
                  return (
                    <>
                      {/* {
                                        task.type === 'theory' && <Theory />
                                    } */}
                      {
                        <Lesson
                          topic={topic}
                          data={exercise && exercise[pages - 1]}
                          langs={["html", "css"]}
                          tasks={_tasks || []}
                          currentTask={currentTask}
                          dropdown={dropdown}
                          sentTask={sentTask}
                        /> // langs: // how many and which editors we want
                      }
                      {/* {
                                        task.type === 'quiz' && <Quiz />
                                    }
                                    {
                                        task.type === 'project' && <Project data={task} langs={['html']} />
                                    } */}
                    </>
                  );
                }
              })
            ) : (
              <Success topic={topic} courseId={courseId} lessonId={lessonId} />
            )
          ) : (
            ""
          )}
        </div>

        {step !== lesson?.topics?.length && (
          <div className="task_footer">
            <>
              {step !== 0 || taskIndex > 0 ? (
                <button
                  onClick={() => {
                    if(taskIndex < 1){
                      navigate(
                        `/task?lesson=${lessonId}&id=${prevTopic?.id}&course=${courseId}`
                      );
                      setStep(step - 1);
                    } else {
                      reverse(currentTask)
                    }
                  }}
                >
                  <ReactSVG
                    className="task_curseArrow"
                    src="/images/arrow.svg"
                  />
                  უკან
                </button>
              ) : (
                <div></div>
              )}
              <div className="task_footer_step">
                {taskIndex < _tasks?.length ? taskIndex + 1 : taskIndex} / {_tasks?.length}
              </div>
              {pages == exercise?.length ? (
                <button
                  className="task_step_next"
                  onClick={() => {
                    if(step ==  lesson?.topics?.length - 1 && taskIndex == _tasks?.length){
                      setStep(step + 1);
                      return;
                    }
                    if (
                      step < lesson?.topics?.length - 1 &&
                      nextTopic !== null
                    ) {
                      if(taskIndex < _tasks?.length){
                        sentTask(currentTask);
                      }else {
                        setTaskIndex(0)
                        setStep(step + 1);
                        navigate(
                          `/task?lesson=${lessonId}&id=${nextTopic?.id}&course=${courseId}`
                        );
                      } 
                    } else {
                      if(taskIndex - 1 < _tasks?.length){
                        sentTask(currentTask);
                      }else {
                        setTaskIndex(0)
                        setStep(step + 1);
                      } 
                    }
                  }}
                >
                  გაგრძელება
                  <ReactSVG
                    className="task_curseArrow2"
                    src="/images/arrow.svg"
                  />
                </button>
              ) : (
                <button
                  className="task_step_next"
                  onClick={() => {
                    console.log("bottom")
                    setPages(pages + 1);
                  }}
                >
                  გაგრძელება
                  <ReactSVG
                    className="task_curseArrow2"
                    src="/images/arrow.svg"
                  />
                </button>
              )}
            </>
          </div>
        )}
      </div>
    </>
  );
}
