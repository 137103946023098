import axios from "axios";

const api = axios.create({
  baseURL: "https://sadmerame.ge/api",
  headers: {
    "Content-Type": "application/json",
  },
});

export async function SENT_DATA(url, body) {
  try {
    const request = await api.put(url, body);

    return request;
  } catch (error) {
    console.error(error);
  }
}

export async function GET_DATA(url) {
  try {
    const response = await api.get(url);

    return response.data;
  } catch (error) {
    console.error(error);
  }
}
