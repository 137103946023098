import React, {useState} from 'react';
import Modal from '../modal';
import Input from '../../inputs/input';
import '../../../styles/components/user/classview.styles.scss';
import { ReactSVG } from 'react-svg';

export default function ClassView({schools, setSchools, schoolView, setSchoolView}) {
    const [isAdd, setIsAdd] = useState(false);
    const [removeMemberId, setRemoveMember] = useState('');
    let currentSchool = schools.filter((e)=> e.id == schoolView.id)[0];

    function memberAdd(name, mail){
        setSchools((prev)=>{
            const newArray = prev.map((e)=> {
                if(e.id == schoolView.id){
                    return {
                        ...e, members: [...e.members, {id: e.members.length + 1, name: name, mail: mail}]
                    }
                }else {
                    return e
                }
            })
            return newArray
        })
    }

    function removeMember(id) {
        setSchools((prev)=>{
            const newArray = prev.map(x=>{
                if(x.id == schoolView.id){
                    console.log(x)
                    let members = x.members.filter((e)=> e.id !== id);
                    return {
                        ...x, members: members
                    }
                }else {
                    return x
                }
            })
            return newArray
        })
    }

    function removeClass(){
        setSchools((prev)=>{
            const newArray = prev.filter(x=>{
                if(x.id !== schoolView.id){
                    return x
                }
            })
            return newArray
        })
        setSchoolView({id: null, open: false})
    }

    return <>
        {
            isAdd ? <AddMember setIsAdd={setIsAdd} memberAdd={memberAdd} /> : 
            <div className="classview">

                <Modal 
                    title={<div className="schoolTitle">53-ე სკოლა, VIIა კლასი <br/> <span className="add_member_span">{currentSchool?.members.length} მოსწავლე</span></div>}
                    onClose={()=> setSchoolView({id: null, open: false})}
                >
                    {
                        currentSchool?.members.map((member, i)=>{
                            return <>
                                <div className='school_member_block'>
                                    <button className="school_member_remove" onClick={()=> removeMember(member.id)}>წაშლა</button>
                                    <div className="school_member_block_image">
                                        <ReactSVG className="school_member_block_image" src="/images/user/Avatars.svg" />
                                    </div>
                                    <div className="school_member_block_info">
                                        <h2>{member.name}</h2>
                                        <h4>{member?.mail}</h4>
                                    </div>
                                    <button className="school_member_block_view">
                                        მოსწავლის ნახვა
                                        <ReactSVG src="/images/user/showmore.svg" />
                                    </button>
                                </div>
                            </>
                        })
                    }

                    <div onClick={()=> setIsAdd(true)} className="school_member_block_add_btn">
                        მოსწავლის დამატება
                        <ReactSVG src="/images/user/plus.svg" />
                    </div>

                    <div className="school_remove">
                        <button onClick={()=> removeClass()}>
                            კლასის წაშლა
                            <ReactSVG src="/images/user/remove.svg" />
                        </button>
                    </div>
                </Modal>

            </div>
        }
    </>
} 

export function AddMember({memberAdd, setIsAdd}) {
    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    
    return <>
        <Modal 
            title={<button className="add_member_back"><ReactSVG src="/images/user/back.svg" /> უკან</button>}
            onClose={()=>setIsAdd(false)}
        >
            <div className="add_user_school">
                <Input 
                    title="მოსწავლის სახელი"
                    value={name} 
                    onChange={(e)=> setName(e)}
                    action="/images/auth/name.svg"
                    classNames="loginInputwith small"
                    type="text"
                />
            </div>
            <div className="add_user_school">
                <Input 
                    title="მოსწავლის ელფოსტა"
                    value={mail} 
                    onChange={(e)=> setMail(e)}
                    action="/images/user/mail.svg"
                    classNames="loginInputwith small"
                    type="text"
                />    
            </div>
            <button className='add_school_btn xxl' onClick={()=>{memberAdd(name, mail); setIsAdd(false)}}>
                დამატება
                <ReactSVG className="add_school_btn_svg" src="/images/user/plus.svg" />
            </button>
        </Modal>
    </>
}