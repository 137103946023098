import axios from "axios";

axios.defaults.baseURL = 'https://sadmerame.ge/api/';

let refresh = false;

axios.interceptors.response.use(resp => resp, async error => {
    if (error.response.status === 401 && !refresh) {
        refresh = true;

        let refresh_token = localStorage.getItem('refresh_token');
        
        if(refresh_token) {
            const response = await axios.post('auth/refresh', {}, {
                headers: {
                  'Authorization': `Bearer ${refresh_token}` 
                },
                withCredentials: true
            });

            localStorage.setItem('refresh_token', response.data['refresh_token'])
            localStorage.setItem('access_token', response.data['access_token'])

            if (response.status === 200) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${response.data['access_token']}`;
                return axios(error.config);
            }
        }
        return;
    }
    refresh = false;
    return error;
});
