import { useQuery } from "@tanstack/react-query";
import { GET_DATA } from "../utils/request";

export default function useLessons(coursesId) {
  const {
    data: lessons,
    isError: isLessonsError,
    isLoading: isLessonsLoading,
    isSuccess: isLessonsSuccess,
    refetch: refetchLessons,
  } = useQuery(
    [`lessons-${coursesId}`, [coursesId]],
    async () => GET_DATA(`/lesson/?course_id=${coursesId}`),
    {
      enabled: coursesId !== undefined,
    }
  );

  return {
    lessons,
    isLessonsError,
    isLessonsLoading,
    isLessonsSuccess,
    refetchLessons,
  };
}
