import {ReactSVG} from 'react-svg';
import "../../../styles/components/auth/modal.styles.scss";

export default function Modal({title, onClose, className, children}) {
    return <>
        <div className={`modalBackgroundClose`} onClick={()=>{onClose()}}></div>
        <div className={`authModal ${className && className}`}>
            {title && 
                <div className="authHeader">
                    <h2>{title}</h2>
                    <button onClick={()=> onClose()}>
                        <ReactSVG src="/images/auth/close.svg" />
                    </button>
                </div>
            }
            <div className="authContent">
                {children}
            </div>
        </div>
    </>
}