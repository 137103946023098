import "./App.css";
import Home from "./pages/main";
import ErrorPage from "./pages/404";
import Practice from "./pages/practice";
import Task from "./pages/courses/task";
import Success from "./pages/auth/success";
import CoursesDetailed from "./pages/courses/detailed";
import MemberProfile from "./pages/user/member";
import MemberProfileView from "./pages/user/member/view";
import TeacherProfile from "./pages/user/teacher";
import UpdateMail from "./pages/user/updateMail";
import Layout from "./components/layout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import About from "./components/modals/about";
import Login from "./components/modals/auth/login";
import Policy from "./components/modals/policy";
import Rules from "./components/modals/rules";
import axios from "axios";
import { getUser } from "./redux/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { useWindowSize } from "./utils/useWindowSize";
import { ReactSVG } from "react-svg";

function App() {
  const {width} = useWindowSize()
  const [modal, setModalOpen] = useState(false);
  const [loginModal, setLoginOpen] = useState(false);
  const [rules, setRules] = useState(false);
  const [policy, setPolicy] = useState(false);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  let refresh_token = localStorage.getItem("refresh_token");

  useEffect(() => {
    if (refresh_token && !user?.user?.id) {
      (async () => {
        try {
          const response = await axios.get("auth/user");

          dispatch(getUser(response.data));

          return response;
        } catch (e) {
          console.log(e);
          return e;
        }
      })();
    }
  }, []);

  return (
    <>
      {modal && <About onClose={() => setModalOpen(false)} />}
      {loginModal && <Login onClose={() => setLoginOpen(false)} />}
      {policy && <Policy onClose={() => setPolicy(false)} />}
      {rules && <Rules onClose={() => setRules(false)} />}
      <Routes>
        {width > 834 && (
          <Route path="/practice" element={<Practice />} />
        )}
        <Route path="/task" element={<Task />} />
        <Route
          element={
            <Layout
              setModalOpen={setModalOpen}
              loginOpen={() => setLoginOpen(true)}
              rulesOpen={() => setRules(true)}
              policyOpen={() => setPolicy(true)}
              user={user}
            />
          }
        >
          <Route
            exact
            path="/"
            element={<Home setModalOpen={setModalOpen} />}
          />
          <Route path="*" element={<ErrorPage />} />
          <Route
            path="/success"
            element={<Success loginOpen={() => setLoginOpen(true)} />}
          />
          <Route path="/courses/:coursesId" element={<CoursesDetailed />} />
          <Route path="/user/profile" element={<MemberProfile />} />
          <Route path="/teacher/profile" element={<TeacherProfile />} />
          <Route path="/user/update" element={<UpdateMail />} />
          <Route path="/user/:id" element={<MemberProfileView />} />
          {useWindowSize().width < 834 && (
            <Route path="/task" element={<DeviceError />} />
          )}
          {useWindowSize().width < 834 && (
            <Route path="/practice" element={<DeviceError />} />
          )}
        </Route>
      </Routes>
    </>
  );
}

export default App;

export function DeviceError() {
  return (
    <div className="device_warn">
      <img src="/images/comp.png" />
      <h2>გასაგრძელებლად შემოდი კომპიუტერით</h2>
      <h4>ეს გვერდი მხოლოდ კომპიუტერითაა ხელმისაწვდომი.</h4>
      <button>
        <ReactSVG src="/images/user/back.svg" /> უკან
      </button>
    </div>
  );
}
