import { useQuery } from "@tanstack/react-query";
import { GET_DATA } from "../utils/request";

export function useCourses() {
  const {
    data: courses,
    isError: isCoursesError,
    isLoading: isCoursesLoading,
    isSuccess: isCoursesSuccess,
    refetch: refetchCourses,
  } = useQuery([`courses`], async () => GET_DATA(`/course`));

  return {
    courses,
    isCoursesError,
    isCoursesLoading,
    isCoursesSuccess,
    refetchCourses,
  };
}
