import Header from './header';
import Footer from './footer';
import {Outlet} from 'react-router-dom';

export default function Layout({setModalOpen, loginOpen, policyOpen, rulesOpen, user, children}){
    return <>
        <Header setModalOpen={setModalOpen} loginOpen={loginOpen} user={user} />
            <Outlet />
        <Footer policyOpen={policyOpen} rulesOpen={rulesOpen} />
    </>
}