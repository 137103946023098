import Modal from './modal';
import '../../../styles/components/auth/check.styles.scss';
import Button from '../../inputs/button';
import { ReactSVG } from 'react-svg';

export default function Check({
    backStep = null,
    onClose,
    children
}) {
    return <>
        <Modal onClose={onClose}>
            {
                backStep && 
                <div 
                    className="prevStep"
                    onClick={()=> backStep()}
                >
                    <ReactSVG src="/images/auth/prev.svg" />
                    <span>უკან</span>
                </div>
            }
            <div>
                {children}
            </div>
        </Modal>
    </>
}