import { ReactSVG } from "react-svg";
import "../../styles/components/task/success.styles.scss";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useLessons from "../../hooks/useLessons";

export default function Success({ topic, courseId, lessonId }) {
  const { lessons } = useLessons(courseId);
  const [next, setNext] = useState({
    course: null,
    lesson: null,
    id: null,
  });
  const navigate = useNavigate();
  useEffect(() => {
    const findIndex = lessons?.findIndex((e) => e.id == lessonId);
    if (findIndex < lessons?.length) {
      const nextLesson = lessons[findIndex + 1];
      setNext({
        course: courseId,
        lesson: nextLesson,
        id: nextLesson?.topics[0]?.id,
      });
    }
  }, [lessons]);

  return (
    <>
      <div className="succes_lesson">
        <ReactSVG src="/images/courses/success.svg" />
        <h2 className="succes_text">
          შენ წარმატებით დაასრულე გაკვეთილი: <br /> {topic?.name}
        </h2>
        <div className="succes_nextlesson">
          <div>
            <h4>შემდეგი გაკვეთილი</h4>
            <h2>{next?.lesson?.name}</h2>
          </div>
          <Link
            to={`/task?lesson=${next?.lesson?.id}&id=${next?.id}&course=${next?.course}`}
          >
            <button className="succes_startNew">
              დაწყება
              <ReactSVG
                className="succes_curseArrow2"
                src="/images/arrow.svg"
              />
            </button>
          </Link>
        </div>
        <Link to={`/courses/${courseId}`}>
          <div className="succes_backTo">
            <ReactSVG className="succes_arrow" src="/images/arrow.svg" />
            <span>კურსის გვერდზე დაბრუნება</span>
          </div>
        </Link>
      </div>
    </>
  );
}
