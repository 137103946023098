import { useQuery } from "@tanstack/react-query";
import { GET_DATA } from "../utils/request";

export default function useTopic(topicId) {
  const {
    data: topic,
    isError: isTopicError,
    isLoading: isTopicLoading,
    isSuccess: isTopicSuccess,
    refetch: refetchTopic,
  } = useQuery(
    [`topic-${topicId}`, [topicId]],
    async () => GET_DATA(`/topic/${topicId}`),
    {
      enabled: topicId !== undefined,
    }
  );

  return {
    topic,
    isTopicError,
    isTopicLoading,
    isTopicSuccess,
    refetchTopic,
  };
}
