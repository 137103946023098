import React, {useState} from 'react';
import Modal from '../auth/modal';
import Input from '../../inputs/input';
import Button from '../../inputs/button';
import { ReactSVG } from 'react-svg';
import '../../../styles/components/user/userupdate.styles.scss';

export default function UpdatePassword({api, onClose}) {
    const [password, setPassword] = useState('');
    const [repeatPass, setRepeatPass] = useState('');
    
    const openNotification = (placement) => {
        api.open({
            message: <div className="passwordMessage"><ReactSVG src="/images/user/succ.svg" /> <span>შენი პაროლი შენახულია</span></div>,
            duration: 3,
            placement
        });
      };

    return <>
        <Modal
            title="პაროლის შესვლა"
            onClose={onClose}
        >
            <div className="passwordUpdate">
                <Input 
                    name="currentpassword"
                    title="მიმდინარე პაროლი"
                    classNames="loginInput"
                    type="password"
                    value="1231203123"
                    disabled
                />
                <Input 
                    name="newpass"
                    title="ახალი პაროლი"
                    classNames="loginInput"
                    type="password"
                    value={password}
                    onChange={(e)=> setPassword(e)}
                />
                <Input 
                    name="repeatpass"
                    title="გაიმეორე პაროლი"
                    classNames="loginInput"
                    type="password"
                    value={repeatPass}
                    onChange={(e)=> setRepeatPass(e)}
                />
                <Button 
                    className="loginBtnAuth"
                    type="primary"
                    onClick={()=> {onClose(); openNotification('bottomRight')}}
                >
                    <span>შენახვა</span>
                    <ReactSVG 
                        className="loginIcon"
                        src="/images/user/save.svg" 
                    />
                </Button>
            </div>
        </Modal>
    </>
}