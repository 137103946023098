import Button from '../../../components/inputs/button';
import { ReactSVG } from "react-svg";
import '../../../styles/pages/success.styles.scss';

export default function Success({loginOpen}) {
    return <>
        <div className="successPage">
            <ReactSVG src="/images/auth/success.svg" />
            <h3>შენ წარმატებით დარეგისტრირდი</h3>
            <Button className="loginBtn" onClick={()=> loginOpen()}>
                <span>ავტორიზაცია</span>
                <ReactSVG 
                    className="loginIcon"
                    src="/images/header/login.svg" 
                />
            </Button>
        </div>
    </>
}