import { Link } from "react-router-dom";
import Button from "../../components/inputs/button";
import "../../styles/pages/home.styles.scss";
import { ReactSVG } from "react-svg";
import React, { useState } from "react";
import { useEffect } from "react";
import * as ANT from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useCourses } from "../../hooks/useCourses";

export default function Home({ setModalOpen }) {
  const { courses } = useCourses();
  let progress = "70";

  return (
    <>
      <div className="slide">
        <div className="content">
          <h3>გახდი პროგრამისტი დამოუკიდებლად</h3>
          <Link to="/practice">
            <Button className="slideBtn">
              <span>დაიწყე ვარჯიში</span>
              <ReactSVG src="images/arrow.svg" />
            </Button>
          </Link>
        </div>
      </div>
      <div className="wrapper"></div>
      <div className="courses">
        {/* <div className="coursesTitle">
                <h3>კურსები</h3>
            </div> */}
        <div className="coursesList">
          {courses?.map((course, key) => {
            return <CourseItem course={course} />;
          })}
        </div>
      </div>
      <div className="aboutProject">
        <div className="projectWall">
          <div className="about">
            <h3>პროექტის შესახებ</h3>
            <p>
              მომხმარებელს ნებისმიერ დროს შეეძლება ამ გვერდზე შემოსვლა და
              ვარჯიში. ასევე, მომხმარებელს შეეძლება დაწერილი კოდის გადმოწერა zip
              ფაილად და დაჰოსტვა
            </p>
            <Button className="courseBtn" onClick={() => setModalOpen(true)}>
              <span>ვრცლად</span>
              <ReactSVG className="courseIcon" src="images/arrow.svg" alt="" />
            </Button>
          </div>
          <div className="WhitePlaceholder"></div>
        </div>
      </div>
    </>
  );
}

export function Skeleton() {
  return (
    <div className="skeltons">
      <ANT.Skeleton.Image size={"large"} active />
      <ANT.Skeleton active></ANT.Skeleton>
    </div>
  );
}

export function CourseItem({ course }) {
  const [state, setState] = useState(true);
  return (
    <>
      {!state && <Skeleton />}
      <div className={`${state && "appendCourse"} course`}>
        <div style={{backgroundImage: `url(${course?.image_path})`}} className="courseImage">
          {/* <LazyLoadImage
            alt={"course"}
            effect="blur"
            src={"/images/courses/courseHTML.png"}
            afterLoad={() => setState(true)}
          /> */}
        </div>
        <div className="courseInfo">
          <div className="courseHeading">
            <h2>{course?.name}</h2>
            <div className="lengthAndTime">
              <div>
                <ReactSVG src="/images/courses/length.svg" />
                <h3>{course?.lesson_number} გაკვეთილი</h3>
              </div>
            </div>
          </div>
          <p></p>
          <Link to={`/courses/${course?.id}`}>
            <Button className="courseBtn">
              <span>კურსის დაწყება</span>
              <ReactSVG className="courseIcon" src="/images/arrow.svg" alt="" />
            </Button>
          </Link>
        </div>

        {/* <div className="courseDetails">
                <div className="courseProgress">
                    <div className="progressNums">
                        4 გაკვეთილი 16-დან
                    </div>
                    <div className="progressBar">
                        <div className="bar"></div>
                        <div className="progress" style={{width: `${progress}%`}}></div>
                    </div>
                </div>
            </div> */}
      </div>
    </>
  );
}
