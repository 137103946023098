import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
      user: undefined
    },
    reducers: {
      getUser: (state, action) => {
        return {
            ...state,
            user: action.payload
        }
      },
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { getUser } = userSlice.actions
  
  export default userSlice.reducer