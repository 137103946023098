import React, {useState} from 'react';
import Modal from '../auth/modal';
import Input from '../../inputs/input';
import Button from '../../inputs/button';
import { ReactSVG } from 'react-svg';
import '../../../styles/components/user/userupdate.styles.scss';
import { SENT_DATA } from '../../../utils/request';
import {notification} from 'antd';

export default function PersonalUpdate({api, data, id, onClose}) {
    const [name, setName] = useState(data?.first_name);
    const [lastname, setLastName] = useState(data?.last_name);
    const [userName, setUserName] = useState(data?.username);
    
    const openNotification = (placement) => {
        api.open({
            message: <div className="passwordMessage"><ReactSVG src="/images/user/succ.svg" /> <span>შენი მონაცემები განახლებულია</span></div>,
            duration: 3,
            placement
        });
      };

    function changeInfo() {
        if(name && userName && lastname) {
            SENT_DATA(`user/${id}`, {
                username: userName,
                first_name: name,
                last_name: lastname
            }).then((e)=>{ 
                onClose()
                if(e?.response?.status == 200){
                 openNotification('bottomRight')
                }else {
                    notification.open({
                        message: <div>შეცდომა</div>,
                        description:
                            <div >{e?.response?.data?.message}</div>,
                        duration: 3,
                    });
                }
            })
        }
    }

    return <>
        <Modal
            title="პირადი ინფორმაცია"
            onClose={onClose}
        >
            <div className="passwordUpdate">
                <Input 
                    title="სახელი"
                    value={name}
                    onChange={(e)=> setName(e)}
                    classNames="loginInput"
                    type="text"
                />
                <Input 
                    title="გვარი"
                    value={lastname}
                    onChange={(e)=> setLastName(e)}
                    classNames="loginInput"
                    type="text"
                />
                <Input 
                    title="მომხმარებლის სახელი"
                    value={userName}
                    onChange={(e)=> setUserName(e)}
                    classNames="loginInput"
                    type="text"
                />
                <Button 
                    className="loginBtnAuth"
                    type="primary"
                    onClick={()=> {changeInfo()}}
                >
                    <span>შენახვა</span>
                    <ReactSVG 
                        className="loginIcon"
                        src="/images/user/save.svg" 
                    />
                </Button>
            </div>
        </Modal>
    </>
}