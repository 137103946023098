import {useEffect, useState} from 'react';
import {Navigate} from "react-router-dom";
import Modal from './modal';
import Check from './check';
import Registration from './registration';
import Input from '../../inputs/input';
import '../../../styles/components/auth/login.styles.scss';
import Button from '../../inputs/button';
import { ReactSVG } from 'react-svg';
import axios from 'axios';
import {useWindowSize} from '../../../utils/useWindowSize';

export default function Login({
    onClose
}) {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [validation, setValidation] = useState(false);

    const [step, setStep] = useState(1);

    const [registration, setRegistration] = useState(false)

    const submit = async e => {
        e.preventDefault();

        if(name && password) {
            const response = await axios.post('auth/login', {
                email_or_username: name, password
            }, {withCredentials: true});

            if(response.status !== 200) {
                setValidation(true)
            }else {
                axios.defaults.headers.common['Authorization'] = `Bearer ${response.data['access_token']}`;

                localStorage.setItem('refresh_token', response.data['refresh_token'])
                localStorage.setItem('access_token', response.data['access_token'])
                window.location.reload(false);
            }
    
        }
    }
    
    if(useWindowSize().width < 614) {
        document.body.style.overflow = "hidden";
        document.body.querySelector('.header').style.background = "#fff";
    }

    return <>
        {
            !registration ? <>
                {
                    step === 1 && 
                    <Modal
                        title="შესვლა"
                        onClose={()=> onClose()}
                    >
                        <div className={`loginTab activeLoginTab`}>
                            <form onSubmit={submit}>
                                <Input 
                                    title="მომხმარებლის სახელი ან ელფოსტა"
                                    value={name}
                                    onChange={(e)=> setName(e)}
                                    placeholder="მომხმარებლის სახელი ან ელფოსტა"
                                    action="/images/auth/name.svg"
                                    classNames="loginInput"
                                    type="text"
                                />

                                <Input 
                                    title="პაროლი"
                                    value={password}
                                    onChange={(e)=> setPassword(e)}
                                    placeholder="შენი პაროლი"
                                    action="/images/auth/password.svg"
                                    classNames="loginInput"
                                    type="password"
                                />

                                <div className="forgotPass">
                                    <button onClick={()=> setStep(2)}>დაგავიწყდა პაროლი?</button>
                                </div>

                                {
                                    validation && 
                                    <div className="validation">
                                        შეყვანილი ელფოსტა ან პაროლი არასწორია
                                    </div>
                                }

                                <Button 
                                    className="loginBtnAuth"
                                    onClick={()=> {
                                        if(!name || !password) setValidation(true)
                                        else setValidation(false)
                                    }}
                                >
                                    <span>შესვლა</span>
                                    <ReactSVG 
                                        className="loginIcon"
                                        src="images/header/login.svg" 
                                    />
                                </Button>
                            </form>
                        </div>
                        <div 
                            className="registration"
                            onClick={()=> setRegistration(true)}
                        >
                            <span>რეგისტრაცია</span>
                            <span><ReactSVG src="/images/auth/arrow.svg" /></span>
                        </div>
                    </Modal>
                }
                {
                    step === 2 && 
                    <Modal
                        title="პაროლის აღდგენა"
                        onClose={()=> onClose()}
                    >
                        <div 
                            className="prevStep"
                            onClick={()=> setStep(1)}
                        >
                            <ReactSVG src="images/auth/prev.svg" />
                            <span>უკან</span>
                        </div>
                        <Input 
                            title="ელფოსტა"
                            value={name}
                            onChange={(e)=> setName(e)}
                            placeholder="ელფოსტა"
                            action="/images/auth/mail.svg"
                            classNames="loginInput"
                            type="mail"
                            actionClass="email"
                        />

                        <Button 
                            className="loginBtnAuth"
                            onClick={()=> setStep(3)}
                        >
                            <span>გაგრძელება</span>
                            <ReactSVG 
                                className="next"
                                src="images/auth/nextStep.svg" 
                            />
                        </Button>
                    </Modal>
                }
                {
                    step === 3 && 
                    <Check 
                        backStep={()=> setStep(1)}
                        onClose={()=> onClose()}
                    >
                        <div className="message">
                            <div className="imgMessage">
                                <ReactSVG src="/images/auth/checkMail.svg" />
                            </div>
                            <div className="messagetext">
                                <h3>შეამოწმე ელფოსტა</h3>
                                <span>
                                    პაროლის აღდგენის ინსტრუქცია ელფოსტაზე გამოგიგზავნეთ.
                                </span>
                            </div>
                            <Button 
                                className="close"
                                onClick={()=> onClose()}
                            >
                                <span>დახურვა</span>
                            </Button>
                        </div>
                    </Check>
                }
            </> : <Registration onClose={()=> onClose()}/>
        }
    </>
}